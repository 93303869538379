import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import axiosInstance from "../helper/axios";

const attApi = axiosInstance;
function Page(props) {
  const { pageid } = useParams();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({ title: "", detail: "" });

  const { setTitle, setOperation, setPLink, siteInfo, user } =
    useContext(UserContext);
  useEffect(() => {
    setTitle(news.title);
    setOperation(news.title);
    setPLink("");
  }, [setTitle, setOperation, setPLink, news]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi
        .get("get-help-item?uniId=" + siteInfo.app_id + "&id=" + pageid)
        .then((response) => {
          setNews(response.data.data);
          setLoading(false);
        });
    }
  }, [siteInfo, pageid]);

  function createMarkup() {
    return { __html: news.detail };
  }
  return (
    <div className={user === null ? "container" : ""}>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <div className="mt-2" dangerouslySetInnerHTML={createMarkup()} />
        </>
      )}

      <div className="flex-grow-1"></div>
    </div>
  );
}

export default Page;
