import React, { useContext, useState } from "react";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import { UserContext } from "./UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { Link } from "react-router-dom";
import CheckBox from "./CheckBox";
import DataLoading from "./DataLoading";
import { notify } from "../helper/Helper";

const attApi = axiosInstance;
function EmploymentForm(props) {
  const [loading, setLoading] = useState(false);
  const { applicantStatus } = useContext(UserContext);
  const [formData, setFormData] = useState(props.formData);
  const [img, setImg] = useState(
    props.formData.document === ""
      ? ""
      : process.env.REACT_APP_BACKEND_URL2 + props.formData.document
  );

  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "cwt") {
      let checked = "0";
      if (formData.cwt === "1") {
        checked = "0";
      } else {
        checked = "1";
      }
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const { name } = e.target;
      setImg((window.URL || window.webkitURL).createObjectURL(img));
      setFormData({
        ...formData,
        [name]: img,
      });
    }
  };

  function saveData(e) {
    e.preventDefault();
    const newErrors = {};

    props.reqCols.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${props.formMeta[key]} cannot be blank.`;
      }
    });

    setErrors(newErrors);

    console.log(newErrors);
    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form ", "error");
      return;
    }

    setLoading(true);
    var formData2 = new FormData();
    Object.keys(formData).forEach((key) => {
      formData2.append(`ApplicantEmployment[${key}]`, formData[key]);
    });

    let url = "add-employment";
    if (props.isNew === false) {
      url = "update-employment?id=" + formData.id;
    }
    attApi
      .post(url, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validating the Form3", "error");
          } else {
            notify(res.data.message, "success");
            props.setIsForm(false);
            props.refreashData();
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Error Validating the Form2", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <TextInput
              name="title"
              label={props.formMeta.title}
              value={formData.title}
              handleInputChange={handleInputChange}
              reqCols={props.reqCols}
              length={150}
              errors={errors}
            />
          </div>
          <div className="col-md-8">
            <TextInput
              name="employer"
              label={props.formMeta.employer}
              value={formData.employer}
              handleInputChange={handleInputChange}
              reqCols={props.reqCols}
              length={150}
              errors={errors}
            />
          </div>

          <div className="col-md-4">
            <DateInput
              name="from"
              label={props.formMeta.from}
              value={formData.from}
              length={10}
              handleInputChange={handleInputChange}
              reqCols={props.reqCols}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            <DateInput
              name="to"
              label={props.formMeta.to}
              value={formData.to}
              length={10}
              handleInputChange={handleInputChange}
              reqCols={props.reqCols}
              errors={errors}
            />
          </div>
          <div className="col-md-4 pt-3">
            <CheckBox
              name="cwt"
              label={props.formMeta.cwt}
              value={formData.cwt}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label" htmlFor="document">
                {props.formMeta.document}
              </label>
              <input
                type="file"
                id="document"
                name="document"
                onChange={onImageChange}
                className={` ${errors["document"] ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{errors["document"]}</div>
            </div>
          </div>
          <div className="col-md-4">
            {img === "" ? (
              <></>
            ) : (
              <img
                src={img}
                height="150px"
                width="150px"
                className={`thumbnail mb-3 `}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="form-group mt-3">
          {loading ? (
            <DataLoading />
          ) : (
            <>
              {props.allowSave ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  name="save-button"
                  onClick={saveData}
                >
                  {props.isNew ? Trans("Save") : Trans("Update")}
                </button>
              ) : (
                <></>
              )}
              {props.listLength === 0 ? (
                <>
                  {applicantStatus && applicantStatus.degreeSelected ? (
                    <Link
                      className="btn btn-outline-primary ml-2"
                      to={"/choose"}
                    >
                      {Trans("Skip & Next")}
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <button
                  className="btn btn-danger ml-2"
                  onClick={() => {
                    props.setIsForm(false);
                  }}
                >
                  {Trans("Close")}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmploymentForm;
