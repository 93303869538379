import React, { useContext, useEffect } from "react";
import { UserContext } from "../components/UserContext";

function Page404(props) {
  const { setTitle,setOperation,setPLink } = useContext(UserContext);
    useEffect(() => {
      setTitle("Page Not Found");
      setOperation("Error");
      setPLink("");
    }, [setTitle, setOperation, setPLink]);
  return (
    <div className="site-error">
      <h1>Not Found (#404)</h1>

      <div className="alert alert-danger">Page not found. </div>

      <p>
        The above error occurred while the Web server was processing your
        request.
      </p>
      <p>Please contact us if you think this is a server error. Thank you.</p>
    </div>
  );
}

export default Page404;
