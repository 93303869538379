import React from "react";
import DropDownList from "./DropDownList";

function DegreePreference(props) {
  const prefNo = props.prefNo;
  const degrees = props.degrees;

  return (
    <div className="row">
      <div className="col-md-6">
        <DropDownList
          name="degree_id"
          label={
            props.colNames.degree_id +
            " : " +
            props.colNames.preference_no +
            " " +
            prefNo
          }
          value={props.data.degree_id}
          handleInputChange={(event) => props.handleInputChange(event, prefNo)}
          reqCols={props.reqCols}
          prompt={"-Select-"}
          listData={degrees}
          errors={props.errors}
        />
      </div>
      
    </div>
  );
}

export default DegreePreference;
