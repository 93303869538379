import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Trans from "../helper/Trans";
import axiosInstance from "../helper/axios";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import { notify } from "../helper/Helper";
import ChatContent from "../components/ChatContent";

const attApi = axiosInstance;
function Chat(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [chat, setChat] = useState([]);
  const [msg, setMsg] = useState("");
  const [img, setImg] = useState("");
  const fileInputRef = useRef();
  const { setTitle, setOperation, user, setPLink } = useContext(UserContext);
  useEffect(() => {
    setTitle("");
    setOperation("");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  const refreshData = useCallback(() => {
    setLoading(true);
    if (user !== null) {
      attApi
        .get("get-chat")
        .then((response) => {
          setChat(response.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setMsg(value);
  };
  function sendMsg(e) {
    e.preventDefault();
    sendMsg2();
  }
  function sendMsg2() {
    if (msg === "" && img === "") {
      return;
    }

    setLoading2(true);
    var formData2 = new FormData();

    if (img !== "") {
      formData2.append(`Chat[attach]`, img);
      formData2.append(`Chat[message]`, "FILE");
    } else {
      formData2.append(`Chat[message]`, msg);
    }

    attApi
      .post("send-msg", formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            notify(res.data.message, "error");
          } else {
            setMsg("");
            setImg("");
            let newChat = chat;
            res.data.data.map(function (d, index) {
              return newChat.push(d);
            });

            setChat(newChat);
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading2(false);
      });
  }

  function btnClicked(e) {
    e.preventDefault();
    fileInputRef.current.click();
  }
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      sendMsg2();
    }
  }
  const onImageChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setImg(img);
    }
  };
  useEffect(() => {
    sendMsg2();
  }, [img]);
  
  return (
    <>
      <div
        className="card chat-sidebar-container sidebar-container"
        data-sidebar-container="chat"
      >
        <div
          className="chat-content-wrap sidebar-content"
          data-sidebar-content="chat"
        >
          <div className=" pl-3 pr-3 pt-2 pb-2 o-hidden box-shadow-1 chat-topbar">
            <span className="link-icon d-md-none" data-sidebar-toggle="chat">
              <i className="icon-regular i-Right ml-0 mr-3"></i>
            </span>
            <div className=" align-items-center">
              <i
                className="i-Reload1 text-22 float-right"
                role="button"
                onClick={refreshData}
              ></i>
              <p className="m-0 text-title text-16 flex-grow-1">
                {Trans("Help Desk")}
              </p>
            </div>
          </div>
          <ChatContent chat={chat} loading={loading} />
          <div className="pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area">
            <form className="inputForm">
              <div className="form-group">
                <textarea
                  className="form-control form-control-rounded"
                  id="msg"
                  placeholder={Trans("Type your message")}
                  name="msg"
                  value={msg}
                  cols="30"
                  rows="3"
                  length="400"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                ></textarea>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1"></div>
                {loading2 ? (
                  <DataLoading />
                ) : (
                  <>
                    <button
                      onClick={btnClicked}
                      className="btn btn-icon btn-rounded btn-primary mr-2"
                      title="Upload image file"
                    >
                      <i className="i-Upload"></i>
                    </button>
                    <input
                      onChange={onImageChange}
                      multiple={false}
                      ref={fileInputRef}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      hidden
                    />

                    <button
                      className="btn btn-icon btn-rounded btn-primary mr-2"
                      onClick={sendMsg}
                      title={Trans("Send Message")}
                    >
                      <i className="i-Paper-Plane"></i>
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
