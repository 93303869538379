import { notify } from "../helper/Helper";
import React from "react";
import Trans from "../helper/Trans";
import axiosInstance from "../helper/axios";

const attApi = axiosInstance;
function AwardList(props) {

  let deleteMsg = Trans("Are you sure you want to delete this item?");
  function deletItem(itemId) {
    if (window.confirm(deleteMsg)) {
      attApi
        .post("delete-award?id=" + itemId)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === false) {
              notify(res.data.message, "error");
            } else {
              notify(res.data.message, "success");
              props.refreashData();
            }
          } else {
            notify(res.data.message, "error");
          }
        })
        .catch((err) => {
          notify("Unkown error occured ", "error");
        });
    }
  }
  return (
    <div style={{ marginLeft: 10 }}>
      <table className="table table-striped table-responsive-sm table-responsive-md tbl-custom">
        <thead>
          <tr>
            <th>{props.formMeta["name"]}</th>
            <th>{props.formMeta["event"]}</th>
            <th>{props.formMeta["type"]}</th>
            <th>{props.formMeta["award_date"]}</th>
            <th>{props.formMeta["document"]}</th>
            <th>{Trans("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((dataItem, index) => (
            <tr key={index}>
              <td>{dataItem.name}</td>
              <td>{dataItem.event}</td>
              <td>{dataItem.type}</td>
              <td>{dataItem.award_date}</td>
              <td>
                {dataItem.document != null ? (
                  <img
                    src={process.env.REACT_APP_BACKEND_URL2 + dataItem.document}
                    height="150px"
                    width="150px"
                    className={`thumbnail mb-3`}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                {props.allowSave ? (
                  <>
                    <span
                      role="button"
                      className="text-20 i-Pen-5"
                      title={Trans("Update")}
                      onClick={() => {
                        props.updateData(dataItem);
                      }}
                    ></span>
                    <span
                      role="button"
                      className="text-20 i-Close ml-2"
                      title={Trans("Delete")}
                      onClick={() => {
                        deletItem(dataItem.id);
                      }}
                    ></span>
                  </>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AwardList;
