import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import axiosInstance from "../helper/axios";
import DegreePreference from "../components/DegreePreference";
import Trans from "../helper/Trans";
import { Link, useNavigate } from "react-router-dom";
import AdmissionPlan from "../components/AdmissionPlan";
import DropDownList from "../components/DropDownList";
import { notify } from "../helper/Helper";
const attApi = axiosInstance;

function Choose(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [degreeSelected, setDegreeSelected] = useState(false);
  const [preferences, setPreferences] = useState({});
  const [admissions2, setAdmissions2] = useState([]);
  const [degrees, setDegrees] = useState({});
  const [quotas, setQuotas] = useState({});
  const [fQuotas, setFQuotas] = useState({});
  const [testCenters, setTestCenters] = useState({});
  const [scholarships, setScholarships] = useState({});
  const [scholarshipDetail, setScholarshipDetail] = useState([]);
  const [allowNext, setAllowNext] = useState(false);
  const [reloadPage, setReloadPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [colNames, setColNames] = useState({});
  const [reqCols, setReqCols] = useState({});
  const [eModel, setEModel] = useState({});
  const [formData, setFormData] = useState({});
  const [formTestCenter, setFormTestCenter] = useState("");
  const [formScholarschip, setFormScholarship] = useState("");
  const [formQuota, setFormQuota] = useState({});
  const [degreeQuota, setDegreeQuota] = useState({});
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [showScholarship, setShowScholarship] = useState(false);
  const [ageIssue, setAgeIssue] = useState("");
  const [qualificationIssue, setQualificationIssue] = useState("");
  const [domicileIssue, setDomicileIssue] = useState("");

  const handelInputChange2 = (e) => {
    const { value } = e.target;
    setFormTestCenter(value);
    setErrors2({});
  };
  const handelInputChange3 = (e) => {
    const { value } = e.target;
    setFormScholarship(value);
    setErrors2({});
  };
  const handleInputChange4 = (e, quotaId) => {
    const { name, value } = e.target;
    let oldNode = formQuota;
    const newData = { ...oldNode, [name]: value };
    setFormQuota(newData);
  };
  const handleInputChange = (e, prefno) => {
    const { name, value } = e.target;
    const nextData = {};
    Object.keys(formData).forEach(function (key2) {
      let oldNode = formData[key2];
      /* eslint-disable eqeqeq */
      if (key2 == prefno) {
        /* eslint-enable eqeqeq */
        if (name === "degree_id") {
          nextData[key2] = {
            ...oldNode,
            [name]: value,
          };
        } else {
          nextData[key2] = { ...oldNode, [name]: value };
        }
      } else {
        nextData[key2] = oldNode;
      }
    });
    setFormData(nextData);
    const newErrors = {};
    Object.keys(errors).forEach(function (key2) {
      let oldNode = errors[key2];
      /* eslint-disable eqeqeq */
      if (key2 == prefno) {
        /* eslint-enable eqeqeq */
        newErrors[key2] = { ...oldNode, [name]: "" };
      } else {
        newErrors[key2] = oldNode;
      }
    });
    setErrors(newErrors);
  };

  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    user,
    applicantStatus,
    getFillStatus,
  } = useContext(UserContext);

  useEffect(() => {
    if (applicantStatus) {
      setAllowNext(applicantStatus.choiceDone);
    }
  }, [applicantStatus]);
  useEffect(() => {
    setTitle("Degree Options");
    setOperation("Select Degree Options");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    if (applicantStatus) {
      if (applicantStatus.choiceDone) {
        setOperation("Update Degree Options");
      }
    }
  }, [applicantStatus, setOperation]);
  useEffect(() => {
    if (siteInfo !== null && Object.keys(preferences).length > 0) {
      setLoading(true);

      attApi.get("get-degree-choose").then((response) => {
        if (response.data.success === true) {
          setColNames(response.data.meta);
          setReqCols(response.data.required);
          setEModel(response.data.model);
          let fdata = response.data.data;
          let dNodes = {};
          if (Object.keys(fdata).length > 0) {
            Object.keys(fdata).forEach(function (key2) {
              dNodes[key2] = fdata[key2];
            });
          }

          let prefCount = siteInfo.pref_count;
          if (prefCount > Object.keys(preferences).length) {
            prefCount = Object.keys(preferences).length;
          }
          for (let i = 1; i <= prefCount; i++) {
            if (!dNodes[i]) {
              dNodes[i] = response.data.model;
            }
          }
          setFormData(dNodes);
          setFormTestCenter(response.data.testCenter);
          setFormScholarship(response.data.scholarship);
          setFormQuota(response.data.quota);
        } else {
          notify(response.data.message, "error");
         // navigate("/");
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    }
  }, [preferences, siteInfo, navigate]);

  useEffect(() => {
    let nfquota = {};
    Object.keys(formData).map((e, i) => {
      if (formData[e].degree_id !== "") {
        let did = formData[e].degree_id;
        if (degreeQuota[did]) {
          let quotaGroup = degreeQuota[did];
          Object.keys(quotaGroup).map((e, i) => {
            if(quotas[e]){
              nfquota[e] = quotas[e];
            }
            return 0;
          });
        }
      }
      return 0;
    });
    setFQuotas(nfquota);
  }, [formData, degreeQuota,quotas]);

  useEffect(() => {
    if (siteInfo) {
      setLoading(true);
      attApi.get("get-preferences").then((response) => {
        if (response.data.success === true) {
          setPreferences(response.data.degrees);
          setTestCenters(response.data.testCenters);
          setScholarships(response.data.scholarships);
          setScholarshipDetail(response.data.scholarshipDetail);
          setDegreeSelected(true);
          setAgeIssue(response.data.ageIssue);
          setQualificationIssue(response.data.qualificationIssue);
          setQuotas(response.data.quota);
          setDegreeQuota(response.data.quotaDegree);
          setDomicileIssue(response.data.domicileIssue);
        } else {
          attApi
            .get("get-all-admissions?uniId=" + siteInfo.app_id)
            .then((response) => {
              setAdmissions2(response.data.data);
              attApi.get("get-applications").then((response) => {
                setSelectedCategories(response.data.data.selectedCats);
              });
            });
        }
        setLoading(false);
      });
    }
  }, [reloadPage, siteInfo]);

  useEffect(() => {
    setDegrees({});

    if (Object.keys(preferences).length > 0) {
      let degreeList = {};
      Object.keys(preferences).forEach(function (key) {
        degreeList[key] = preferences[key].name;
      });
      setDegrees(degreeList);
    }
  }, [preferences]);

  function saveData(e) {
    let btnName = e.target.name;
    e.preventDefault();
    const newErrors = {};
    let newErrors2 = {};
    setLoading2(true);
    var formData2 = new FormData();

    Object.keys(formData).forEach((key) => {
      Object.keys(formData[key]).forEach((key2) => {
        formData2.append(
          `ProvisionalDegreeChoice[${key}][${key2}]`,
          formData[key][key2]
        );
      });
    });

    Object.keys(formQuota).forEach((key) => {
      formData2.append(
        `ProvisionalDegreeChoice[quota][${key}]`,
        formQuota[key]
      );
    });

    formData2.append(`ProvisionalDegreeChoice[test_center]`, formTestCenter);
    formData2.append(`ProvisionalDegreeChoice[scholarship]`, formScholarschip);
    attApi
      .post("/add-degree-choice", formData2)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
              newErrors2 = res.data.errors[errorKey];
            });
            // console.log(newErrors2);
            setErrors(newErrors);
            setErrors2(newErrors2);
            notify(res.data.message, "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
            if (btnName === "savenext-button") {
              navigate(`/contact`);
            }
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading2(false);
      });
  }

  const createPreferences = () => {
    let rows = [];

    if (siteInfo !== null) {
      if (Object.keys(preferences).length > 0 && reqCols.length > 0) {
        if (Object.keys(colNames).length > 0) {
          let prefCount = siteInfo.pref_count;
          if (prefCount > Object.keys(preferences).length) {
            prefCount = Object.keys(preferences).length;
          }
          for (let i = 1; i <= prefCount; i++) {
            let rowData = formData[i] ? formData[i] : eModel;
            let rowError = errors[i] ? errors[i] : {};
            rows.push(
              <DegreePreference
                key={i}
                prefNo={i}
                degrees={degrees}
                testCenters={testCenters}
                reqCols={reqCols}
                colNames={colNames}
                data={rowData}
                handleInputChange={handleInputChange}
                errors={rowError}
              />
            );
          }

          if (siteInfo.quota_on_selection === false && Object.keys(fQuotas).length > 0) {
            rows.push(
              <div key="49" className="border-top">
                <h5 className="mt-3">{Trans("Additional Admission Categories")}</h5>
                <p>Please select optional categories from below, leave blank if not eligible as per criteria or you dont want to apply.</p>
              </div>
            );
            Object.keys(fQuotas).map((e, i) => {
              const colName = "quota_" + e;
              return rows.push(
                <div className="row" key={i + 50}>
                  <div className="col-md-6">
                    <DropDownList
                      name={colName}
                      label={quotas[e].name}
                      value={formQuota[colName]}
                      handleInputChange={handleInputChange4}
                      reqCols={reqCols}
                      prompt={"-Select-"}
                      listData={quotas[e].categories}
                      errors={errors2}
                    />
                  </div>
                </div>
              );
            });
          }

          if (Object.keys(testCenters).length > 0) {
            rows.push(
              <div key="99" className="border-top">
                <h5 className="mt-3">{Trans("Select Test Center")}</h5>
              </div>
            );
            rows.push(
              <div className="row" key="100">
                <div className="col-md-6">
                  <DropDownList
                    name="test_center"
                    label={colNames.test_center}
                    value={formTestCenter}
                    handleInputChange={handelInputChange2}
                    reqCols={reqCols}
                    prompt={"-Select-"}
                    listData={testCenters}
                    errors={errors2}
                  />
                </div>
              </div>
            );
          }

          if (Object.keys(scholarships).length > 0) {
            rows.push(
              <div className="row" key="101">
                <div className="col-md-6">
                  <DropDownList
                    name="scholarships"
                    label={colNames.scholarship + " (optional for eligible candidates only)"}
                    value={formScholarschip}
                    handleInputChange={handelInputChange3}
                    reqCols={reqCols}
                    prompt={"-Select-"}
                    listData={scholarships}
                    errors={errors2}
                  />
                  <br/>
                  <p>Please read our <u><b><a rel="noopener noreferrer" href="https://admissions.duet.edu.pk/wp-content/uploads/2023/07/scholarship-policy.pdf" target="_blank">scholarship application policies</a></b></u> before applying.</p>
                  
                </div>
                <div className="col-md-6">
                  {showScholarship === false ? (
                    <button
                      className="btn btn-info mt-4"
                      onClick={() => {
                        setShowScholarship(true);
                      }}
                    >
                      {Trans("More Detail")}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          }

          if (showScholarship) {
            rows.push(
              <div className="card mt-3">
                <div className="card-body">
                  <h4>
                    <u>{Trans("Scholarship Detail")}</u>
                  </h4>
                  {scholarshipDetail.map((e, i) => {
                    return (
                      <div key={i} className="border-bottom">
                        <h5>{e.name}</h5>
                        <p>
                          <b>{Trans("Detail")}</b> : {e.detail}
                        </p>
                        <p>
                          <b>{Trans("Eligibility")}</b> :{e.eligibility}
                        </p>
                        <p>
                          <b>{Trans("Sponsor")}</b> :{e.sponsor}
                        </p>
                        <p>
                          <b>{Trans("Benefit")}</b> : {e.more}
                        </p>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                      setShowScholarship(false);
                    }}
                  >
                    {Trans("Hide")}
                  </button>
                </div>
              </div>
            );
          }
        }
      }
    }

    return rows;
  };
  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          {degreeSelected ? (
            <div className="card">
              <div className="card-body">
                {Object.keys(preferences).length > 0 ? (
                  createPreferences()
                ) : (
                  <></>
                )}
                {ageIssue !== "" ? (
                  <p className="text-danger">{ageIssue}</p>
                ) : (
                  <></>
                )}
                {qualificationIssue !== "" ? (
                  <p className="text-danger">{qualificationIssue}</p>
                ) : (
                  <></>
                )}
                {domicileIssue !== "" ? (
                  <p className="text-danger">{domicileIssue}</p>
                ) : (
                  <></>
                )}
                {loading2 ? (
                  <DataLoading />
                ) : (
                  <>
                    <div className="form-group mt-3">
                      {Object.keys(preferences).length > 0 ? (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          name="save-button"
                          onClick={saveData}
                        >
                          {Trans("Save")}
                        </button>
                      ) : (
                        <></>
                      )}
                      {allowNext ? (
                        <Link
                          className="btn btn-outline-primary ml-2"
                          to={applicantStatus.scholarshipInfoReq ?"/scholarship":"/apply"}
                        >
                          {Trans("Next")}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              {admissions2.length === 0 ? (
                <></>
              ) : (
                admissions2.map(function (d, index) {
                  return (
                    <AdmissionPlan
                      data={d}
                      key={index}
                      user={user}
                      navigateTo={"/choose"}
                      setReloadPage={setReloadPage}
                      selectedCategories={selectedCategories}
                    />
                  );
                })
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Choose;
