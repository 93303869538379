import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import DataLoading from "../components/DataLoading";
const attApi = axiosInstance;
function Help(props) {
  const [loading, setLoading] = useState(false);
  const [help, setHelp] = useState([]);
  const [shownTab, setShownTab] = useState("");
  const { setTitle, setOperation, setPLink, siteInfo ,user} =
    useContext(UserContext);

  useEffect(() => {
    setTitle("Help");
    setOperation("Help");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("help?uniId=" + siteInfo.app_id).then((response) => {
        setHelp(response.data.data);
        if (Object.keys(response.data.data).length > 0) {
          setShownTab(Object.keys(response.data.data)[0]);
        }
        setLoading(false);
      });
    }
  }, [siteInfo]);

  function createMarkup(html) {
    return { __html: html };
  }
  return (
    <div className={user === null ? "container mt-3" : ""}>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          {Object.keys(help).length === 0 ? (
            <img src="/imgs/nodatafound.png" alt="Data not found." />
          ) : (
            <>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {Object.keys(help).map(function (d, index) {
                  var tabName = d;
                  tabName = tabName.replace(/\s+/g, "-").toLowerCase();
                  return (
                    <li className="nav-item" key={index}>
                      <span
                        className={
                          "nav-link " + (d === shownTab ? "active" : "")
                        }
                        id={tabName + "-tab"}
                        role="button"
                        onClick={() => {
                          setShownTab(d);
                        }}
                      >
                        {d}
                      </span>
                    </li>
                  );
                })}
              </ul>

              <div className="card">
                <div className="card-body mt-3">
                  {Object.keys(help).map(function (d, index) {
                    var tabName = d;
                    tabName = tabName.replace(/\s+/g, "-").toLowerCase();
                    return (
                      <div
                        key={index}
                        className="tab-pane "
                        id={tabName}
                        role="tabpanel"
                        aria-labelledby={tabName + "-tab"}
                      >
                        {help[d] && help[d].length > 0 ? (
                          <>
                            {help[d].map(function (d2, index2) {
                              return (
                                <div
                                  
                                  className={"mb-3 pb-3 border-bottom " + (d === shownTab ? "" : " hide")}
                                  key={index2}
                                >
                                  <h4>{d2.title} </h4>
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      d2.detail
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Help;
