import React from "react";
import DropDownList from "./DropDownList";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import Trans from "../helper/Trans";

function MoreDetailItem(props) {
  const stepNo = props.data.step_did;
  const stepType = props.data.type;
  let reqCols = [];
  if (stepType === "2") {
    reqCols.push("test_name");
    reqCols.push("test_total");
    reqCols.push("test_obtained");
  }
  if (props.data.req_upload === true) {
    reqCols.push("path");
  }
  if (reqCols.length === 0) {
    reqCols.push("test_name");
  }
  return (
    <div>
      <h5>{props.data.name}</h5>
      <div className="row">
        {stepType === "2" ? (
          <>
            <div className="col-md-3">
              <DropDownList
                name="test_name"
                label={props.colNames.test_name}
                value={props.data.test_name}
                handleInputChange={(event) =>
                  props.handleInputChange(event, stepNo)
                }
                reqCols={reqCols}
                prompt={"-Select-"}
                listData={props.data.list}
                errors={props.errors[stepNo]}
              />
            </div>
            <div className="col-md-3">
              <NumberInput
                name="test_total"
                label={props.colNames.test_total}
                value={props.data.test_total}
                handleInputChange={(event) =>
                  props.handleInputChange(event, stepNo)
                }
                reqCols={reqCols}
                errors={props.errors[stepNo]}
                min={0}
                max={5000}
                step={0.01}
              />
            </div>
            <div className="col-md-3">
              <NumberInput
                name="test_obtained"
                label={props.colNames.test_obtained}
                value={props.data.test_obtained}
                handleInputChange={(event) =>
                  props.handleInputChange(event, stepNo)
                }
                reqCols={reqCols}
                errors={props.errors[stepNo]}
                min={0}
                max={5000}
                step={0.01}
              />
            </div>
            <div className="col-md-12">
            <p className="text-info">If you have not taken any standardized test, please select 'None' from the list and enter '0' for both Total Marks and Obtained Marks.</p>
            </div>
          </>
        ) : (
          <div className="col-md-9">
            <TextInput
              name="test_name"
              label={Trans("Detail")}
              value={props.data.test_name}
              handleInputChange={(event) =>
                props.handleInputChange(event, stepNo)
              }
              reqCols={reqCols}
              errors={props.errors[stepNo]}
              length={100}
            />
          </div>
        )}
        {props.data.req_upload === true ? (
          <>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="path">
                  {props.colNames.path}
                </label>
                <input
                  type="file"
                  id="path"
                  name="path"
                  onChange={(event) => props.onImageChange(event, stepNo)}
                  className={` ${
                    props.errors[stepNo] && props.errors[stepNo]["path"]
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {props.errors[stepNo] && props.errors[stepNo]["path"]}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              {props.img && props.img !== "" ? (
                <img
                  src={props.img}
                  height="150px"
                  width="150px"
                  className={`thumbnail mb-3 `}
                  alt=""
                />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-8"></div>
        )}
      </div>
    </div>
  );
}

export default MoreDetailItem;
