import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import { notify } from "../../helper/Helper";
import TextInput from "../../components/TextInput";
import DataLoading from "../../components/DataLoading";

const changeApi = axiosInstance;

function ChangePassword(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  let initError = {
    oldpass: "",
    pass1: "",
    pass2: "",
  };
  let initData = {
    oldpass: "",
    pass1: "",
    pass2: "",
  };

  const [values, setValues] = useState(initData);
  const [errorText, setErrorText] = useState(initError);

  const reqCols = ["oldpass", "pass1", "pass2"];
  const [formMeta] = useState({
    oldpass: "Old Password",
    pass1: "New Password",
    pass2: "Confirm Password",
  });

  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText(initError);
  }

  function changePass(e) {
    e.preventDefault();
    const newErrors = {};
    reqCols.forEach((key) => {
      if (!values[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    setErrorText(newErrors);
    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      return;
    }
    setLoading(true);
    var formData = new FormData();
    formData.append("old_pass", values.oldpass);
    formData.append("pass1", values.pass1);
    formData.append("pass2", values.pass2);
    changeApi
      .post("/changepassword", formData)
      .then((res) => {
        if (res.data.success === true) {
          notify(res.data.message, "success");
        } else {
          notify(res.data.message, "error");
          setErrorText(res.data.errors);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    setTitle("Change Password");
    setOperation("Change Password");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-4">
                <TextInput
                  type="password"
                  name="oldpass"
                  label={formMeta.oldpass}
                  value={values.oldpass}
                  length={30}
                  handleInputChange={handleChange}
                  reqCols={reqCols}
                  errors={errorText}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextInput
                  type="password"
                  name="pass1"
                  label={formMeta.pass1}
                  value={values.pass1}
                  length={30}
                  handleInputChange={handleChange}
                  reqCols={reqCols}
                  errors={errorText}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <TextInput
                  type="password"
                  name="pass2"
                  label={formMeta.pass2}
                  value={values.pass2}
                  length={30}
                  handleInputChange={handleChange}
                  reqCols={reqCols}
                  errors={errorText}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  {loading ? (
                    <DataLoading />
                  ) : (
                    <button onClick={changePass} className="btn btn-primary">
                      Change
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
