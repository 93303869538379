import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import Trans from "../helper/Trans";
import axiosInstance from "../helper/axios";
import DataLoading from "../components/DataLoading";

const attApi = axiosInstance;
function Terms(props) {
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState("");
  const { siteInfo } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("terms?uniId=" + siteInfo.app_id).then((response) => {
        setTerms(response.data.data);
        setLoading(false);
      });
    }
  }, [siteInfo]);

  function createMarkup() {
    return { __html: terms };
  }
  return (
    <div>
      {loading ? (
        <DataLoading />
      ) : (
        <div className="container">
          <h3 className="mt-3">{Trans("Terms & Conditions")}</h3>
          <hr />
          <div className="mt-2" dangerouslySetInnerHTML={createMarkup()} />
        </div>
      )}
    </div>
  );
}

export default Terms;
