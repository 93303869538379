import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import DataLoading from "../components/DataLoading";
import FilterComponent from "../components/FilterComponent";

const attApi = axiosInstance;
function Faq(props) {
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const [filterText, setFilterText] = React.useState("");
  const { setTitle, setOperation, setPLink, siteInfo, user } =
    useContext(UserContext);
    useEffect(() => {
        setTitle("FAQs");
        setOperation("FAQs");
        setPLink("");
      }, [setTitle, setOperation, setPLink]);
    

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("faq?uniId=" + siteInfo.app_id).then((response) => {
        setFaqs(response.data.data);
        setLoading(false);
      });
    }
  }, [siteInfo]);

  const filteredFaqs = faqs.filter(
    (item) =>
      item.question.toLowerCase().includes(filterText.toLowerCase()) ||
      item.answer.toLowerCase().includes(filterText.toLowerCase())
  );
  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };
  return (
    <div>
      {loading ? (
        <DataLoading />
      ) : (
        <div className={user === null ? "container" : ""}>
         
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
          {filteredFaqs.length === 0 ? (
            <img src="/imgs/nodatafound.png" alt="Data not found." />
          ) : (
            filteredFaqs.map(function (d, index) {
              return (
                <div className="card mb-3" key={index}>
                  <div className="card-body">
                    <span className="text-primary text-20">Q</span> {d.seq} :
                    <b>{d.question}</b>
                    <br />
                    <p>
                      <span className="text-primary text-20">A</span>:{" "}
                      {d.answer}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
}

export default Faq;
