import React from "react";
import Trans from "../helper/Trans";
import RequiredMark from "./RequiredMark";

function DropDownList(props) {
  
  return (
    <div className={`form-group field-${props.name}`}>
      <label className="control-label" htmlFor={props.name}>
        {props.label}
        {props.reqCols && props.reqCols.includes(props.name) && (
          <RequiredMark/>
        )}
      </label>
      <select
        id={props.name}
        className={`form-control ${
          props.errors && props.errors[props.name] ? "is-invalid" : ""
        }`}
        name={props.name}
        value={props.value}
        onChange={props.handleInputChange}
        aria-required={props.reqCols.includes(props.name) && "true"}
      >
        {props.prompt && <option value="">{Trans (props.prompt)}</option>}
        {props.listData && Object.keys(props.listData).map((e, i) => {
          return (
            <option key={e} value={e}>
              {props.listData[e]}
            </option>
          );
        })}
      </select>

      <div className="invalid-feedback">{props.errors && props.errors[props.name]}</div>
    </div>
  );
}

export default DropDownList;
