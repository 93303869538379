import React, { useContext } from "react";
import { UserContext } from "../components/UserContext";

function Privacy(props) {
    const { setTitle,setOperation ,setPLink} = useContext(UserContext);
    setTitle('QOBE Privacy Policy');
    setOperation('Privacy Policy');
    setPLink("");
  return (
    <div>
      <div className="container content">
        <div className="row-fluid privacy">
          <p>
            <span>QOBE is a product of Alfoze (www.alfoze.com)</span>
          </p>

          <p>
            <span>
              Alfoze is committed to protecting your privacy and complying with
              applicable data protection laws. This Privacy Policy outlines how
              Alfoze collects, uses, discloses, and safeguards information about
              you in connection with the QOBE portals and other interactions
              with you. We also describe the choices you have about your
              information.
            </span>
          </p>

          <p>
            <b>
              <span>Effective date: Oct 1, 2022</span>
            </b>
            <span>.</span>
          </p>

          <ol start="1" type="1">
            <li>
              <a href="#point01">
                <b>
                  <span>Our Services</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point02">
                <b>
                  <span>What information do we collect?</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point03">
                <b>
                  <span>How do we use your information?</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point04">
                <b>
                  <span>
                    What are your rights regarding the information about you?
                  </span>
                </b>
              </a>
            </li>
            <li>
              <a href="#point05">
                <b>
                  <span>Who has access to your information?</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point06">
                <b>
                  <span>What international data transfers occur at QOBE ?</span>
                </b>
              </a>
            </li>
            <li>
              <a href="#point07">
                <b>
                  <span>How do we safeguard your information?</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point08">
                <b>
                  <span>How long do we retain your information?</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point09">
                <b>
                  <span>
                    QOBE’s roles under the GDPR and UK data protection laws
                  </span>
                </b>
              </a>
            </li>
            <li>
              <a href="#point10">
                <b>
                  <span>Changes to this Privacy Policy</span>
                </b>
              </a>
              <span></span>
            </li>
            <li>
              <a href="#point11">
                <b>
                  <span>How to contact us</span>
                </b>
              </a>
              <span></span>
            </li>
          </ol>

          <h3 className="pt-3 pb-3" id="point01">
            1. Our Services
          </h3>

          <h5>QOBE Services</h5>

          <p>
            <span>
              QOBE is an Outcome Based Learning Management System which is
              offered on SaaS Model with multiple subscription models. QOBE
              offers features set to manage complete academic lifecycle
              including course registration, assessment &amp; evaluation,
              virtual class room, e-learning, continuous quality improvement,
              outcome based reporting, learning outcomes attainment at cohort /
              course / student level, survey design &amp; publishing, alumni
              management and issuance of transcript / certificate / degree.
            </span>
          </p>

          <h5>Your Privacy Obligations</h5>

          <p>
            <span>
              We rely on you to comply with applicable privacy laws when
              collecting, using, or disclosing information about individuals
              through the Services, including obtaining any necessary consents
              and providing any necessary notices. If we receive any questions
              or complaints regarding your use of the Services, we will direct
              the request to you for further assistance.
            </span>
          </p>

          <h5>Business Purposes</h5>

          <p>
            <span>
              Our Services are only for business purposes related to learning
              domain.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point02">
            2. What information do we collect?
          </h3>

          <p>
            <span>
              We collect information about you as reasonably necessary for the
              following activities:
            </span>
          </p>

          <h5>Using our Services</h5>

          <p>
            <span>
              We may collect the following information when you use our
              Services:
            </span>
          </p>

          <h5>Account information:</h5>

          <ul type="disc">
            <li>
              <span>
                Your contact and profile information including your name, email
                address, organization name, and address; your preferences such
                as language, time zone, and the types of communications you
                would like to receive from us; and image (if you choose to
                provide this). We may also obtain this information if you choose
                to use a social login service, such as Facebook Login, to create
                or access your account.
              </span>
            </li>
            <li>
              <span>
                Billing and other payment information (if you sign up for a paid
                service or purchase a Third-Party Service), including payment
                method details, such as credit card number (not collecting at
                this stage)
              </span>
            </li>
            <li>
              <span>
                The Services you have acquired from us, including the type of
                plan, number of user accounts, and transaction information
                related to the Services.
              </span>
            </li>
          </ul>

          <h5>Content:</h5>

          <ul type="disc">
            <li>
              <span>Academic records like grades, assignments</span>
            </li>
            <li>
              <span>
                Academic support material in multiple format (text, documents,
                audio / video / images{" "}
                <span>
                  <span className="GramE">etc</span>
                </span>
                <span className="GramE"> )</span>
              </span>
            </li>
            <li>
              <span>Academia process forms</span>
            </li>
            <li>
              <span>
                Survey results data required for academic, administrative,
                planning or monitoring purposes.
              </span>
            </li>
            <li>
              <span>
                Your social profile information for Social Networks you choose
                to connect to the Services. For example, your Facebook profile
                information may include your Facebook username and profile
                image. (Social logins are not enabled yet)
              </span>
            </li>
            <li>
              <span>
                A specific location such as an address, a city, or a place (for
                example, a restaurant) if you choose to share this information.
              </span>
            </li>
            <li>
              <span>
                Your messages, posts, comments, images, advertising, and other
                material you curate on and upload to the Services;{" "}
              </span>
            </li>
          </ul>

          <h5>Logs, usage, and support data:</h5>

          <ul type="disc">
            <li>
              <span>
                Log data, which may include your IP address, the address of the
                web page you visited before using the Services, your browser
                type and settings, your device information (such as make, model,
                and OS), the date and time when you used the Services,
                information about your browser configuration, language
                preferences, unique identifiers, and&nbsp;
              </span>
            </li>
            <li>
              <span>
                Usage data and analytics, which may include the frequency of
                login, and the different types of activity undertaken by users
                such as frequently accessed areas of the Services.
              </span>
            </li>
            <li>
              <span>
                General Location information, such as IP address and the region
                in which you are located when you are logging in and using the
                Services, in accordance with the settings on your device.
              </span>
            </li>
            <li>
              <span>
                Customer support questions, issues, and general feedback that
                you choose to provide.
              </span>
            </li>
          </ul>

          <h5>Surveys, events, marketing and other activities</h5>

          <p>
            <span>
              Surveys, contests, events (such as webinars and in-person events)
              for those we host or are affiliated with:
            </span>
          </p>

          <ul type="disc">
            <li>
              <span>
                Contact information, such as your name, email address, telephone
                number, organization name and address.
              </span>
            </li>
            <li>
              <span>Participation, attendance, feedback and opinions.</span>
            </li>
            <li>
              <span>
                General information about your organization that you choose to
                provide, such as annual{" "}
              </span>
            </li>
          </ul>

          <h3 className="pt-3 pb-3" id="point03">
            3. How do we use your information?
          </h3>

          <p>
            <span>
              We use your information for the purposes described below:
            </span>
          </p>

          <h5>Providing and securing our Services</h5>

          <ul type="disc">
            <li>
              <span>
                We need to identify and authenticate our users to ensure, for
                example, that only those authorized users are able to use the
                Services for their organization, and to make changes to their
                accounts.
              </span>
            </li>
            <li>
              <span>
                We use information that you provide when signing up to set up
                your account, process payments, contact you regarding the
                Services, and manage your account.
              </span>
            </li>
            <li>
              <span>
                We use your contact information and information related to your
                request to respond to your inquiries, manage our contract with
                you, respond to your questions and requests, and send you
                updates and information about the Services.
              </span>
            </li>
            <li>
              <span>
                We use logging and other data such as general location
                information—for example, the IP address of your browser or
                device, to help us manage the performance, security and
                compliance of the Services.
              </span>
            </li>
            <li>
              <span>
                We analyze your information, including usage information, your
                feedback, support queries, and survey responses to identify
                issues and help us understand how you use the Services so that
                we can make improvements to our Services and to provide
                aggregated user analytics, insights and measurement reports.
              </span>
            </li>
          </ul>

          <h5>Communicating with you</h5>

          <p>
            <span>
              We use your contact information where appropriate to send you
              information about our Services, events, marketing communications
              (consistent with your preferences)
            </span>
          </p>

          <p>
            <span>
              We use email statistics, such as open rates, to assess the
              effectiveness of, and to make improvements to our communications.
              We also use engagement analytics to better understand your needs
              so that we can provide the information and services that would be
              more suitable for you.
            </span>
          </p>

          <h5>Improving our websites and applications</h5>

          <p>
            <span>
              We use information about you to help us understand usage patterns
              and other activities on our websites and applications so that we
              can diagnose problems and make improvements, including enhancing
              usability and security. We also use website personalization
              software to help us present information on our websites that may
              be more relevant to you, such as displaying resources applicable
              to your industry or organization size.
            </span>
          </p>

          <p>
            <span>
              If you choose to provide information about you, your usage of
              social media services and other feedback during telephone calls
              and other interactions to our customer support and sales teams, we
              may use, monitor, and record this information for training
              purposes, to make improvements to our internal sales and marketing
              processes, and to improve our Services.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point04">
            4. What are your rights regarding the information about you?
          </h3>

          <h5>Services information</h5>

          <p>
            <span>
              When using our Services, you may access, update, or correct most
              of your Account information by logging in to your account to edit
              your profile or organization record (directly or through your
              admin, as per <span className="GramE">policy )</span>
            </span>
          </p>

          <p>
            <span>
              If you have requests that cannot be carried out by logging in to
              your account, such as accessing additional information or deleting
              information about you, for the QOBE services, please email
              our&nbsp;privacy team.
            </span>
          </p>

          <p>
            <span>
              Please note that we may need to retain certain information about
              you for as long as you maintain an account for our Services, to
              provide you with our Services, for record keeping purposes, for
              payment processing, to comply with our legal and regulatory
              obligations, to resolve disputes, or to enforce the&nbsp;
            </span>
          </p>

          <h5>Marketing emails, advertising and website browsing</h5>

          <p>
            <span>
              For marketing communications, you may opt out of marketing
              communications sent by QOBE by accessing unsubscribe option given
              in email.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point05">
            5. Who has access to your information?
          </h3>

          <p>
            <span>
              QOBE does not rent or sell your information. We restrict access to
              your information to authorized employees and we do not share your
              information with third parties except in the circumstances
              explained below.
            </span>
          </p>

          <h5>Employees and Authorized Contractors</h5>

          <p>
            <span>
              Our employees and authorized contractors may need to access
              information about you when they require this information to
              perform their job. For example, a customer support representative
              would need access to your account to validate your identity and
              respond to your question or request; our email communications team
              would need access to your contact information to ensure this
              information is sent correctly and any unsubscribe requests are
              properly managed; and our security staff would need to review
              information to investigate attempted denial of service attacks,
              fraudulent account activity, or other attempts to compromise the
              Services.
            </span>
          </p>

          <p>
            <span>
              All our employees and contractors are required to agree to
              maintain the confidentiality and protect the privacy of your
              information.
            </span>
          </p>

          <h5>Service Providers, Authorized Resellers, and Partners</h5>

          <p>
            <span>
              We will share limited information about you to authorized service
              providers we use for marketing services, communicating with you,
              managing our customer database, the provision of professional
              services, and providing and managing the Services (including
              hosting data centers, securing our Services, and payment
              processing).
            </span>
          </p>

          <p>
            <span>
              We limit the number of service providers who are permitted to
              process your Content for the purpose of assisting us in delivering
              the Services.{" "}
            </span>
          </p>

          <p>
            <span>
              Where you have purchased a service from an authorized reseller or
              partner, we may provide information about you to (and may receive
              information about you from) the reseller or partner as necessary
              to support your use of the service you purchased.
            </span>
          </p>

          <p>
            <span>
              When sharing your information with any of the above service
              providers, resellers and partners, we ensure they agree to
              obligations consistent with this Privacy Policy and any other
              appropriate confidentiality and security measures, and only use
              your information to carry out the Services and your requests.
            </span>
          </p>

          <p>
            <span>
              We may also participate in and run marketing events (e.g.
              conferences, webinars, and provide resources) with sponsors and
              other organizations. Where the sponsors or other organizations
              wish to collect your information for their marketing purposes,
              while we may facilitate this (e.g. information may be collected on
              the same registration form), they will be doing so independently
              under their own policies. We will advise you and provide you an
              opportunity for you to share your information with the sponsors or
              other organizations for such purposes, either upon registration or
              during the event.
            </span>
          </p>

          <h5>Customer Organizations</h5>

          <p>
            <span>
              Where your employer or an entity has purchased Services on your
              behalf, we may disclose information about you such as your name
              and email address, and some usage information including whether a
              user has logged in to the Service, frequency of login, time spent
              using the Services, and enrollment and completion of QOBE Academy
              courses to assist your employer or the entity in managing its use
              and maximizing the value of the Services.
            </span>
          </p>

          <h5>Successor and Affiliated Entities</h5>

          <p>
            <span>
              We may share information about you among QOBE-controlled
              affiliates and subsidiaries, and they will protect your
              information in a manner that is consistent with this Privacy
              Policy and where applicable, in accordance with the privacy policy
              specific to the entity.{" "}
            </span>
          </p>

          <p>
            <span>
              We may also disclose your information as part of a corporate
              transaction such as a merger or sale of assets. If we do, we will
              inform such entities of the requirement to handle your information
              in accordance with this Privacy Policy, or inform you that you are
              covered by a new privacy policy.
            </span>
          </p>

          <h5>
            Law Enforcement, Government Agencies, and Professional Advisors
          </h5>

          <p>
            <span>
              We may need to disclose information about you where we believe
              that it is reasonably necessary to comply with a law or
              regulation, or if we are otherwise legally required to do so, such
              as in response to a court order or legal process, or to establish,
              protect, or exercise our legal rights or to defend against legal
              claims or demands. For governmental data access requests
              concerning you or your organization, we would first attempt to
              redirect the request to you and/or we would first attempt to
              notify you unless we are legally prohibited from doing so.
            </span>
          </p>

          <p>
            <span>
              In addition, we may disclose information about you if we believe
              it is necessary to investigate, prevent, or take action: (a)
              against illegal activities, fraud, situations involving potential
              threats to our rights or property (or to the rights or property of
              those who use our Services), or to protect the personal safety of
              any person; or (b) regarding situations that involve the security
              of our Services, abuse of the Services infrastructure, or the
              Internet in general (such as voluminous spamming, or denial of
              service attacks).
            </span>
          </p>

          <p>
            <span>
              We also use professional advisors, including lawyers and
              accountants, and may be required to disclose information about you
              when engaging them for their services and as necessary for audits,
              financial and other regulatory reviews.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point06">
            6. What international data transfers occur at QOBE?
          </h3>

          <p>
            <span>
              QOBE also uses third-party service providers, such as managed
              hosting providers, credit card processors, and technology partners
              to provide the software, networking, infrastructure and other
              services required to operate the Services. These third-party
              providers may process or store personal data on servers outside of
              the EEA, UK, and Switzerland, including in Canada or the US. We
              rely on adequacy (if sent to Canada) and standard contractual
              clauses (if sent to the US or onward to other countries) to ensure
              that information about you is lawfully transferred under EU law.{" "}
            </span>
          </p>

          <p>
            <span>
              The third-party service providers we use to help us deliver the
              Services{" "}
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point07">
            7. How do we safeguard your information?
          </h3>

          <p>
            <span>
              QOBE maintains industry standard security safeguards to protect
              your information. This includes ensuring our employees receive
              appropriate security and privacy training and guidance so they are
              aware of the measures they need to implement to protect your
              information.
            </span>
          </p>

          <p>
            <span>
              Access controls are in place to limit access to your information
              to those who need it to perform their jobs. For example,
              information about you may be provided to our customer support
              specialists to help you with your requests. Individuals who are
              permitted to handle your information must adhere to
              confidentiality obligations.
            </span>
          </p>

          <p>
            <span>
              We encrypt data in transit and at rest, where appropriate, to
              ensure that your information is kept private. We undertake service
              provider security and privacy reviews to ensure that service
              providers follow our stringent requirements to safeguard your
              information, and we also enter into data protection agreements
              with our service providers. All payment information is fully
              encrypted and handled only by PCI certified organizations.{" "}
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point08">
            8. How long do we retain your information?
          </h3>

          <p>
            <span>
              We retain your information as long as required to provide the
              Services requested by you, for record keeping purposes, to comply
              with our legal obligations, resolve disputes, and enforce the
              terms for the Services. After it is no longer necessary for us to
              retain information about you, or otherwise upon your request, we
              will dispose of it in a secure manner or anonymize the
              information.
            </span>
          </p>

          <p>
            <span>
              Aggregated data is used by QOBE for analysis, product improvement,
              and troubleshooting purposes.{" "}
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point09">
            9. QOBE roles under the GDPR and UK data protection laws
          </h3>

          <p>
            <span>
              Depending on the situation and the type of data involved, QOBE may
              act as a data controller or a data processor.
            </span>
          </p>

          <h5>QOBE as a data controller</h5>

          <p>
            <span>QOBE may act as a data controller when we are:</span>
          </p>

          <ul>
            <li>
              Collecting information from you to set up and administer your QOBE
              account (for example, Account information such as your name and
              email address);
            </li>
            <li>Monitoring usage information on our website;</li>
            <li>
              Managing your contact and other related information to send
              marketing, Services, and other communications to you;
            </li>
            <li>Responding to a support or general inquiry; and</li>
            <li>Recruiting individuals for job opportunities.</li>
          </ul>

          <h5>Legal bases for processing when QOBE is a data controller</h5>

          <p>
            <span>
              The legal bases for processing information about you include:
            </span>
          </p>

          <ul>
            <li>
              Your consent (for example, when you have provided your information
              to sign up for an account or for a webinar; or you have provided
              your employment history when applying for a job). Where we rely on
              your consent to process personal data, you have the right to
              withdraw your consent at any time.
            </li>
            <li>
              It is necessary to perform a contract (for example, we may need
              your information to fulfill our obligations of providing Services
              to you under the terms relevant to the Services you have
              acquired).
            </li>
            <li>
              Legitimate interest (for example, to provide, maintain and improve
              the Services for you, to maintain the security of the Services,
              and to attract new customers to maintain demand for the Services,
              all of which are described in the &quot;
            </li>
            <li>
              In some cases, we may have a legal obligation to process your
              personal data to comply with relevant laws (for example,
              processing payroll and tax information to comply with relevant
              employment and tax legislation); or processing is necessary to
              protect your vital interests or those of another person (for
              example, obtaining health-related information during a medical
              emergency).
            </li>
          </ul>

          <h5>QOBE as a data processor</h5>

          <p>
            <span>
              Where you are using our Services and making decisions about the
              personal data that is being processed in the Services (including
              selecting the Social Network accounts you wish to connect to the
              Services, or uploading and using Content), you are acting as a
              data controller and QOBE is acting as a data processor.
            </span>
          </p>

          <p>
            <span>
              There are certain obligations under the GDPR that you have as a
              data controller, including being responsible for managing Content
              on the Services. As a data processor, QOBE will only access and
              process Content to provide you with the Services in accordance
              with your instructions (which you provide through the Services),
              the Terms of Service and applicable laws. As part of delivering
              the Services, we may process Content to further improve the
              Services, such as enhancing usability and developing new features.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point10">
            10. Changes to this Privacy Policy
          </h3>

          <p>
            <span>
              We may make changes to this privacy policy at any time to reflect
              updates to our Services, applicable laws, and other factors. We
              will include a prominent notice on this website and/or our
              Services if we make any material changes, but we encourage you to
              stay informed by reviewing this policy periodically.
            </span>
          </p>

          <h3 className="pt-3 pb-3" id="point11">
            11. How to contact us
          </h3>

          <p>
            <span>
              If you have any questions, concerns or feedback, please&nbsp;email
              our Privacy team and Privacy Officer/Data Protection Officer on
              info@alfoze.com
            </span>
          </p>

          <p>
            <span>Or send a letter to:</span>
          </p>

          <p>
            <span>
              <span>Alfoze</span>
            </span>
            <span>
              {" "}
              Technologies <span>Pvt</span> Ltd
            </span>
          </p>

          <p>
            <span>3B , Software Technology Park</span>
          </p>

          <p>
            <span>Service Road North , I-9/3</span>
          </p>

          <p>Islamabad , Pakistan</p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
