import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";

const attApi = axiosInstance;
function AdmissionCategory(props) {
  const navigate = useNavigate();
  function apply(id) {
    var formData = new FormData();

    attApi
      .post("/select-degree?id=" + id, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            notify(res.data.message, "error");
          } else {
            if (props.navigateTo === "/choose") {
              props.setReloadPage(Math.random());
            } else {
              navigate(props.navigateTo);
            }
          }
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  return (
    <div>
      <h4>{props.data.name}</h4>
      <p>
        {props.data.from} - {props.data.to}
      </p>
      <p>{props.data.description}</p>
      {props.data.degrees.length === 0 ? (
        <></>
      ) : (
        props.data.degrees.map(function (d, index) {
          return (
            <div className="row" key={index}>
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-10">
                <h6>
                  <b>{d.name}</b>
                </h6>
                <p>
                  {d.max_age === "" ? (
                    <></>
                  ) : (
                    <>
                      {Trans("Age Range")}: {d.min_age}-{d.max_age}
                    </>
                  )}
                </p>
                <p>{d.description}</p>
              </div>
              <div className="col-md-2">
                {props.allowSave ? (
                  <>
                    {props.user === null ? (
                      <Link
                        className="btn btn-primary float-right"
                        role="button"
                        title="Apply"
                        to="/signup"
                      >
                        {Trans("Apply")}
                      </Link>
                    ) : props.selectedCategories[props.data.id] ? (
                      <button
                        className="btn btn-primary"
                        name="login-button"
                        disabled={true}
                      >
                        {Trans("Applied")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        name="login-button"
                        onClick={() => apply(d.id)}
                      >
                        {Trans("Apply")}
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default AdmissionCategory;
