import React, { useEffect, useRef } from "react";
import DataLoading from "./DataLoading";

function ChatContent(props) {
  const mutationRef = useRef();

  const incrementMutationCount = () => {
    const elementRef = document.getElementById("chat-content");
    elementRef.scrollTop = elementRef.scrollHeight;
  };

  useMutationObserver(mutationRef, incrementMutationCount);
  return (
    <div
      id="chat-content"
      className="chat-content perfect-scrollbar ps ps--active-y"
      data-suppress-scroll-x="true"
      style={{ overflow: "auto" }}
      ref={mutationRef}
    >
      {props.loading ? (
        <DataLoading />
      ) : (
        <>
          {props.chat.length === 0 ? (
            <></>
          ) : (
            props.chat.map(function (d, index) {
              return (
                <div key={index}>
                  {d.side === "R" ? (
                    <div className="d-flex mb-4" key={index}>
                      <div className="message flex-grow-1">
                        <div className="d-flex">
                          <p className="mb-1 text-title text-16 flex-grow-1">
                            {d.name}
                          </p>
                          <span className="text-small text-muted">
                            {d.dated}
                          </span>
                        </div>
                        {d.type === "C" ? (
                          <p className="m-0">{d.msg}</p>
                        ) : (
                          <img
                            className="img-responsive"
                            src={process.env.REACT_APP_BACKEND_URL2 + d.file}
                            alt={d.msg}
                          />
                        )}
                        
                      </div>
                      <img
                        className="avatar-sm rounded-circle ml-3"
                        src={process.env.REACT_APP_BACKEND_URL2 + d.dp}
                        alt={d.name}
                      />
                    </div>
                  ) : (
                    <div className="d-flex mb-4 user" key={index}>
                      <img
                        className="avatar-sm rounded-circle mr-3"
                        src={process.env.REACT_APP_BACKEND_URL2 + d.dp}
                        alt={d.name}
                      />
                      <div className="message flex-grow-1">
                        <div className="d-flex">
                          <p className="mb-1 text-title text-16 flex-grow-1">
                            {d.name}
                          </p>
                          <span className="text-small text-muted">
                            {d.dated}
                          </span>
                        </div>
                        {d.type === "C" ? (
                          <p className="m-0">{d.msg}</p>
                        ) : (
                          <img
                            className="img-responsive"
                            src={process.env.REACT_APP_BACKEND_URL2 + d.file}
                            alt={d.msg}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
}

export default ChatContent;
const useMutationObserver = (
  ref,
  callback,
  options = {
    characterData: true,
    childList: true,
    subtree: true,
    attributes: true,
    attributeOldValue: true,
    characterDataOldValue: true,
  }
) => {
  useEffect(() => {
    if (ref.current.firstChild) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [ref, callback, options]);
};
