import React from 'react';
import Trans from '../helper/Trans';

function FilterComponent(props) {
    return (
        <div className="search-bar searchDiv mb-3 ">
            <input value={props.filterText} onChange={props.onFilter}  type="text" placeholder= {Trans("Search text")} className="searchText" />
            <i className="search-icon text-muted i-Magnifi-Glass1"></i>
        </div>
        
    );
}
export default FilterComponent;