import React from "react";

function Footer(props) {
  return (
    
        <>
        <div className="app-footer">
          <div className="row">
            
            <div className="col-md-5">
              <p >
                2015-2024 © All Rights Reserved. Alfoze Technologies (Pvt) Ltd.
              </p>
            </div>
          </div>
        </div>
        </>
      
  );
}

export default Footer;
