import React, { useCallback, useContext, useEffect, useState } from "react";
import DataLoading from "../components/DataLoading";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
//import AdmissionPlan from "../components/AdmissionPlan";
import Applications from "../components/Applications";
import Trans from "../helper/Trans";
import { Link } from "react-router-dom";
import RecentNews from "../components/RecentNews";

const attApi = axiosInstance;
function Index(props) {
  const [loading, setLoading] = useState(false);
  const [frontPage, setFrontPage] = useState("");
  const [appMsg, setAppMsg] = useState("");
  const [admissions2, setAdmissions2] = useState([]);
  const [applications, setApplications] = useState({});
 // const [selectedCategories, setSelectedCategories] = useState([]);
 // const [allowSave, setAllowSave] = useState(false);
  const [haveFrontPage, setHaveFrontPage] = useState(false);
 
  const { setTitle, setOperation, user, setPLink, siteInfo, applicantStatus } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("");
    setOperation("");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  const refreshData = useCallback(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("frontpage?id=" + siteInfo.app_id).then((response) => {
        if (
          user == null ||
          (applicantStatus && applicantStatus.applied === false)
        ) {
          setFrontPage(response.data.html);
          setHaveFrontPage(true);
        }
        setAppMsg(response.data.app_msg);
        setLoading(false);
      });
      if (
        user == null ||
        (applicantStatus && applicantStatus.applied === false)
      ) {
        attApi
          .get("get-all-admissions?uniId=" + siteInfo.app_id)
          .then((response) => {
            setAdmissions2(response.data.data);
 //           setAllowSave(response.data.allowSave);
          });
      }
    }
  }, [siteInfo, applicantStatus, user]);

  function createMarkup() {
    return { __html: frontPage };
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const refreshAppData = useCallback(() => {
    if (user !== null) {
      attApi.get("get-applications").then((response) => {
        setApplications(response.data.data.applications);
        //setSelectedCategories(response.data.data.selectedCats);
      });
      props.getNotificationCount();
    }
  }, [user]);

  useEffect(() => {
    refreshAppData();
  }, [refreshAppData]);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <div className={user !== null ? "m-lg-1" : "m-lg-5"}>
          {appMsg === "" ? (
            <></>
          ) : (
            <div className="alert alert-danger" role="alert">
              {appMsg}
            </div>
          )}
         
          {user != null ? (
            <>
              {applicantStatus && (applicantStatus.personalInfo === false || applicantStatus.catSelected === false) ? (
                <>
                  <p>
                    {Trans(
                      "You've successfully completed the first step of the signup process. Now, please proceed to the next step and start filling out your admission form to complete your application."
                    )}
                    <br />
                    <br />
                    <Link to= {applicantStatus.catSelected === false?"/category":"/profile"} className="btn btn-outline-success ">
                      {Trans("Start Filling Application Form")}
                    </Link>
                  </p>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {applicantStatus &&
          applicantStatus.applied === true &&
          applicantStatus.application_submitted === false ? (
            <>
              <p className="text-danger text-14">
                {Trans(
                  "Oops! It looks like you haven't submitted your application yet. Just click the link below to complete the process."
                )}
              </p>
              <Link to="/apply" className="btn btn-primary">
                {Trans("Proceed to Submit")}
              </Link>
            </>
          ) : (
            ""
          )}
          {user !== null && Object.keys(applications).length > 0 ? (
            <Applications data={applications} refreshAppData={refreshAppData} />
          ) : (
            <></>
          )}
          {haveFrontPage ? (
            <div className="card mb-2">
              <div className="card-body">
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row mt-3">
            {admissions2.length === 0 ? (
              <></>
            ) : <></>}

            <div className="col-md-4">
              <RecentNews />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
