import React from "react";

function CheckBox(props) {
  return (
    <div className={`form-group field-${props.name}`}>
      <label className="checkbox checkbox-primary">
        <input
          type="checkbox"
          checked={props.value === "1" ? "checked" : ""}
          name={props.name}
          maxLength={props.length}
          onChange={props.handleInputChange}
        />
        <span>{props.label}</span>
        <span className="checkmark"></span>
      </label>
    </div>
  );
}

export default CheckBox;
