import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import axiosInstance from "../helper/axios";
import { Link } from "react-router-dom";
import Trans from "../helper/Trans";

const attApi = axiosInstance;
function RecentNews(props) {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  const { siteInfo } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi
        .get("get-recent-news?uniId=" + siteInfo.app_id)
        .then((response) => {
          setNews(response.data.data);
          setLoading(false);
        });
    }
  }, [siteInfo]);

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          {news.length === 0 ? (
            <></>
          ) : (
            <div className="card">
              <div className="card-body">
                <h4>{Trans("News & Updates")}</h4>

                <ul>
                  {news.map(function (d, index) {
                    return (
                      <li className="mb-3 text-16" key={index}>
                        <Link to={`/newsitem/${d.id}`}>{d.title}</Link>
                        <br />
                        <b>{d.dated}</b>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RecentNews;
