import React, {  useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {  useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import AcademicForm from "../components/AcademicForm";
const attApi = axiosInstance;

function Academic2(props) {
  const [loading, setLoading] = useState(false);
  
  const [reqCols, setReqCols] = useState([]);
  const [boards, setBoards] = useState({});
  const [universities, setUniversities] = useState({});

  const [allowSave, setAllowSave] = useState(false);
  const [requiredDegrees, setRequiredDegrees] = useState({});
  const navigate = useNavigate();
  const { setTitle, setOperation, setPLink, siteInfo } =
    useContext(UserContext);

  const YesNoList = { 0: Trans("No"), 1: Trans("Yes") };
  const currentYear = new Date().getFullYear();
  let YearsList = {};
  for (let year = currentYear; year >= currentYear - 30; year--) {
    YearsList[`${year}`] = year;
  }

  useEffect(() => {
    setTitle("Academic Detail");
    setOperation("Academic Detail List");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi
        .get("get-academic-meta")
        .then((response) => {
          if (response.data.success === false) {
            navigate("/profile");
            setLoading(false);
            return;
          }
          
          setReqCols(response.data.required);
          setAllowSave(response.data.allowSave);
          setRequiredDegrees(response.data.requiredDegrees);
          setBoards(response.data.boards);
          setUniversities(response.data.universities);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteInfo, navigate]);

  return (
    <div className="">
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <p style={{ color: "#663399" }}>
            {siteInfo?.academic_info.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
          {Object.keys(requiredDegrees).map((e, i) => {
            let degree = requiredDegrees[e];

            return (
              <AcademicForm
                key={i}
                head={degree.name}
                studyLevelId={e}
                studyLevel={degree.dropdown}
               
                formMeta={degree.meta}
                reqCols={reqCols}
               
                YesNoList={YesNoList}
                YearsList={YearsList}
                boards={degree.use_boards === "1" ? boards : universities}
                useBoards={degree.use_boards}
                allowSave={allowSave}
                showAwait={degree.allow_awaiting}
                instructions={degree.instruction}
              />
            );
          })}
          
        </>
      )}
    </div>
  );
}

export default Academic2;
