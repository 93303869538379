import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";
import DropDownList from "../components/DropDownList";
import TextInput from "../components/TextInput";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";
import NumberInput from "../components/NumberInput";
const attApi = axiosInstance;
function Scholarship(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formMeta, setFormMeta] = useState([]);
  const [errors, setErrors] = useState({});
  const [reqCols, setReqCols] = useState([]);
  const [allowSave, setAllowSave] = useState(false);
  const [pmsg, setPmsg] = useState(false);
  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    applicantStatus,
    getFillStatus,
  } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Scholarship Information");
    setOperation("Add Scholarship Information");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    if (applicantStatus) {
      if (applicantStatus.scholarshipInfo) {
        setOperation("Update Scholarship Information");
      }
    }
  }, [applicantStatus, setOperation]);

  const houseStatus = { O: "Own", R: "Rented" };

  useEffect(() => {
    if (
      siteInfo !== null &&
      applicantStatus != null &&
      applicantStatus.scholarshipInfoReq
    ) {
      setLoading(true);
      attApi
        .get("get-scholarship-info")
        .then((response) => {
          if (response.data.success === false) {
            navigate("/profile");
            setLoading(false);
            return;
          }
          setFormData(response.data.data);
          setFormMeta(response.data.meta);
          setReqCols(response.data.required);
          setAllowSave(response.data.allowSave);
          if(applicantStatus.scholarshipInfo === false && applicantStatus.application_submitted === true){
            setAllowSave(true);
            setPmsg(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteInfo, navigate, applicantStatus]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function saveData(e) {
    let btnName = e.target.name;
    setLoading2(true);
    e.preventDefault();
    var formData2 = new FormData();
    const newErrors = {};
    reqCols.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    if (formData.house_status === "R" && formData.house_rent === "") {
      newErrors.house_rent = `${formMeta.house_rent} cannot be blank.`;
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      setLoading2(false);
      return;
    }

    setLoading2(true);

    Object.keys(formData).forEach((key) => {
      formData2.append(`Scholarship[${key}]`, formData[key]);
    });

    attApi
      .post("/add-scholarship-info", formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validatingsthe Form", "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
            if (btnName === "savenext-button") {
              navigate(`/apply`);
            }
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        setLoading(false);
        setLoading2(false);
      });
  }
  return (
    <div className="">
      {loading ? (
        <DataLoading />
      ) : (
        <>
          {applicantStatus && applicantStatus.scholarshipInfoReq ? (
            <>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <NumberInput
                        name="study_members"
                        label={formMeta.study_members}
                        value={formData.study_members}
                        min={0}
                        max={50}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md-4">
                      <NumberInput
                        name="gas_bill"
                        label={formMeta.gas_bill}
                        value={formData.gas_bill}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="telephone_bill"
                        label={formMeta.telephone_bill}
                        value={formData.telephone_bill}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md-4">
                      <NumberInput
                        name="electricity_bill"
                        label={formMeta.electricity_bill}
                        value={formData.electricity_bill}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="total_bill"
                        label={formMeta.total_bill}
                        value={formData.total_bill}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="education_exp"
                        label={formMeta.education_exp}
                        value={formData.education_exp}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="kitichen_exp"
                        label={formMeta.kitichen_exp}
                        value={formData.kitichen_exp}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="medical_exp"
                        label={formMeta.medical_exp}
                        value={formData.medical_exp}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="misc_exp"
                        label={formMeta.misc_exp}
                        value={formData.misc_exp}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md-4">
                      <DropDownList
                        name="house_status"
                        label={formMeta.house_status}
                        value={formData.house_status}
                        listData={houseStatus}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        prompt={"-Select-"}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name="house_rent"
                        label={formMeta.house_rent}
                        value={formData.house_rent}
                        min={0}
                        max={99999999}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-12">
                      <TextInput
                        name="other_income"
                        label={formMeta.other_income}
                        value={formData.other_income}
                        length={500}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    {loading2 ? (
                      <DataLoading />
                    ) : (
                      <>
                        {allowSave ? (
                          <>
                          {
                            pmsg ?
                            <p className="text-danger">{Trans('You are seeing this message because you have already submitted the application. Please review and save your changes carefully. After saving, the form will be locked and no further edits will be possible.')}</p>
                            :<></>
                          }
                          <button
                            type="submit"
                            className="btn btn-primary"
                            name="save-button"
                            onClick={saveData}
                          >
                            {Trans(
                              applicantStatus &&
                                applicantStatus.scholarshipInfo === true
                                ? "Update"
                                : "Save"
                            )}
                          </button>
                          </>
                        ) : (
                          <></>
                        )}
                        {applicantStatus &&
                        applicantStatus.scholarshipInfo === true &&
                        applicantStatus.application_submitted === false ? (
                          <button
                            type="submit"
                            className="btn btn-outline-secondary m-2"
                            name="savenext-button"
                            onClick={saveData}
                          >
                            {Trans("Next")}
                          </button>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>{Trans("Scholarship Information is not required!")}</p>
          )}
        </>
      )}
    </div>
  );
}

export default Scholarship;
