import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

function Sidemenu(props) {
  const { user, siteInfo, applicantStatus } = useContext(UserContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling behavior
    });
  };
  return (
    <>
      {user ? (
        <Sidebar width="100%" collapsed={false}>
          <Menu>
            <MenuItem component={<Link to="/" />} onClick={scrollToTop}>
              <span className="">
                <i className=" i-Home1 sidemenu-icon mr-1"></i>{" "}
                {props.menuNames.home}
              </span>
            </MenuItem>
            {applicantStatus ? (
              <MenuItem
                component={<Link to="/category" />}
                onClick={scrollToTop}
              >
                <span
                  className={
                    applicantStatus && applicantStatus.catSelected === true
                      ? " text-green"
                      : ""
                  }
                >
                  <i className="i-Check sidemenu-icon  mr-1"></i>
                  {props.menuNames.category}
                </span>
              </MenuItem>
            ) : (
              <></>
            )}
            {applicantStatus && applicantStatus.catSelected === true ? (
              <MenuItem
                component={<Link to="/profile" />}
                onClick={scrollToTop}
              >
                <span
                  className={
                    applicantStatus && applicantStatus.personalInfo === true
                      ? " text-green"
                      : ""
                  }
                >
                  <i className="i-Administrator sidemenu-icon  mr-1"></i>
                  {props.menuNames.profile}
                </span>
              </MenuItem>
            ) : (
              <></>
            )}

            {applicantStatus && applicantStatus.personalInfo === true ? (
              <>
                <MenuItem
                  component={<Link to="/contact" />}
                  onClick={scrollToTop}
                >
                  <span
                    className={
                      applicantStatus && applicantStatus.contactInfo === true
                        ? "text-green "
                        : ""
                    }
                  >
                    <i className="i-Email sidemenu-icon mr-1"></i>
                    {props.menuNames.contact}
                  </span>
                </MenuItem>
                {siteInfo && siteInfo.show_gaurdian === "1" ? (
                  <MenuItem
                    component={<Link to="/guardian" />}
                    onClick={scrollToTop}
                  >
                    <span
                      className={
                        applicantStatus && applicantStatus.guardianInfo === true
                          ? "text-green"
                          : ""
                      }
                    >
                      <i className="i-Business-ManWoman sidemenu-icon mr-1"></i>
                      {props.menuNames.guardian}
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                <MenuItem
                  component={<Link to="/academic" />}
                  onClick={scrollToTop}
                >
                  <span
                    className={
                      applicantStatus && applicantStatus.academicInfo === true
                        ? "text-green"
                        : ""
                    }
                  >
                    <i className="i-Receipt sidemenu-icon  mr-1"></i>
                    {props.menuNames.academic}
                  </span>
                </MenuItem>
                {applicantStatus && applicantStatus.moreInfoReq === true ? (
                  <MenuItem
                    component={<Link to="/moreinfo" />}
                    onClick={scrollToTop}
                  >
                    <span
                      className={
                        applicantStatus && applicantStatus.moreInfo === true
                          ? " text-green"
                          : ""
                      }
                    >
                      <i className="i-ID-3 sidemenu-icon  mr-1"></i>
                      {props.menuNames.moreinfo}
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                {siteInfo && siteInfo.show_award === "1" ? (
                  <MenuItem component={<Link to="/award" />}>
                    <span className="">
                      <i className="i-Width-Window sidemenu-icon mr-1"></i>
                      {props.menuNames.employment}
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}

                {applicantStatus &&
                applicantStatus.application_submitted === false &&
                applicantStatus.academicInfo === true ? (
                  <MenuItem
                    component={<Link to="/choose" />}
                    onClick={scrollToTop}
                  >
                    <span
                      className={
                        applicantStatus && applicantStatus.choiceDone === true
                          ? "text-green"
                          : ""
                      }
                    >
                      <i className="i-Suitcase sidemenu-icon  mt-1"></i>{" "}
                      {props.menuNames.choose}
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}

                {applicantStatus && applicantStatus.scholarshipInfoReq === true ? (
                  <MenuItem
                    component={<Link to="/scholarship" />}
                    onClick={scrollToTop}
                  >
                    <span
                      className={
                        applicantStatus && applicantStatus.scholarshipInfo === true
                          ? "text-green"
                          : ""
                      }
                    >
                      <i className="i-Coins-2 sidemenu-icon  mt-1"></i>{" "}
                      {props.menuNames.scholarship}
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Menu>
        </Sidebar>
      ) : (
        <></>
      )}
    </>
  );
}
export default Sidemenu;
