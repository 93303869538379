import React from "react";
import RequiredMark from "./RequiredMark";

function TextArea2(props) {

    return (
        <div className={`form-group field-${props.name}`}>
            <label className="control-label" htmlFor={props.name}>
                {props.label}
                {props.reqCols.includes(props.name) && (
                    <RequiredMark/>
                )}
            </label>

            <textarea
                id={props.name}
                className={`form-control ${props.errors[props.name] ? "is-invalid" : ""}`}
                name={props.name}
                value={props.value}
                maxLength={props.length}
                rows="3"
                placeholder={props.placeholder}
                aria-required={props.reqCols.includes(props.name) && "true"}
                onChange={props.handleInputChange}
            ></textarea>

            <div className="invalid-feedback">{props.errors[props.name]}</div>
        </div>
    );
}

export default TextArea2;
