import React from "react";
import RequiredMark from "./RequiredMark";

function DropDownGroupedList(props) {
  return (
    <>
      {props.listData && Object.keys(props.listData).length > 0 ? (
        <div className={`form-group field-${props.name}`}>
          <label className="control-label" htmlFor={props.name}>
            {props.label}
            {props.reqCols.includes(props.name) && (
              <RequiredMark/>
            )}
          </label>
          <select
            id={props.name}
            className={`form-control ${
              props.errors[props.name] ? "is-invalid" : ""
            }`}
            name={props.name}
            value={props.value}
            onChange={props.handleInputChange}
            aria-required={props.reqCols.includes(props.name) && "true"}
          >
            {props.prompt && <option value="">{props.prompt}</option>}
            {props.listData &&
              Object.keys(props.listData).map((e, i) => {
                return (
                  <optgroup label={e} key={e}>
                    {Object.keys(props.listData[e]).map((e1, i1) => {
                      return (
                        <option key={e1} value={e1}>
                          {props.listData[e][e1]}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
          </select>

          <div className="invalid-feedback">{props.errors[props.name]}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default DropDownGroupedList;
