import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../helper/axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";

function ResetPasswordRequest(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const { setTitle, setOperation, user, setPLink, siteInfo } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("");
    setOperation("");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  let navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  function handleChange(e) {
    setEmail(e.target.value);
    setErrorText("");
  }

  const loginApi = axiosInstance;

  let ErrorText = Trans("Email can not be blank!");
  function validate(e) {
    e.preventDefault();
    if (email === "") {
      setErrorText(ErrorText);
      return;
    }
    setLoading(true);
    var formData = new FormData();
    formData.append("PasswordResetRequest[app_id]", siteInfo.app_id);
    formData.append("PasswordResetRequest[email]", email);
    loginApi
      .post("/generate-otp", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            setErrorText(res.data.message);
            notify(res.data.message, "error");
          } else {
            notify(res.data.message, "success");
            navigate("/validateotp");
          }
        } else {
          setErrorText("Invalid Email Provided!");
        }
      })
      .catch((err) => {
        setErrorText(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="auth-layout-wrap">
      <div className="auth-content" style={ {marginTop:"20px"}}>
        <div className="card o-hidden">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4">
                <div className="auth-logo text-center mb-4">
                  <a href="/">
                    <img src="/imgs/logo.png" alt="Admission Pro" />
                  </a>
                </div>
                <div className="site-login">
                  <h3>Enter Email </h3>

                  <p>
                    {Trans(
                      "Please fill out the following fields to request OTP for Password Reset"
                    )}
                    :
                  </p>

                  <form id="login-form" data-gtm-form-interact-id="0">
                    <div
                      className={
                        "form-group field-otp required " +
                        (errorText === "" ? "" : "has-error")
                      }
                    >
                      <label htmlFor="email">{Trans("Email")}</label>
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        name="email"
                        autoFocus=""
                        aria-required="true"
                        data-gtm-form-interact-field-id="0"
                        aria-invalid="false"
                        onChange={handleChange}
                        value={email}
                      />

                      <div className="help-block">{errorText}</div>
                    </div>

                    <div className="form-group mt-3">
                      {loading ? (
                        <DataLoading loadTxt="Requesting OTP ....." />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          name="login-button"
                          onClick={validate}
                        >
                          {Trans("Request")}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordRequest;
