import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import Trans from "../helper/Trans";
import axiosInstance from "../helper/axios";
import DataLoading from "../components/DataLoading";
import { Link } from "react-router-dom";

const attApi = axiosInstance;
function News(props) {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({});

  const { setTitle, setOperation, setPLink, siteInfo, user } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("News");
    setOperation("News");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("get-all-news?uniId=" + siteInfo.app_id).then((response) => {
        setNews(response.data.data);
        setLoading(false);
      });
    }
  }, [siteInfo]);

  return (
    <div>
      {loading ? (
        <DataLoading />
      ) : (
        <div className={user === null ? "container" : ""}>
          {user === null ? (
            <>
              <h3 className="mt-3">{Trans("News")}</h3>
              <hr />
            </>
          ) : (
            <></>
          )}

          {news.length === 0 ? (
            <img src="/imgs/nodatafound.png" alt="Data not found." />
          ) : (
            Object.keys(news).map((e, i) => {
              return (
                <div key={i}>
                  <h4>{e}</h4>
                  <ul>
                    {news[e].map(function (d, index) {
                      return (
                        <li className="mb-3 text-16" key={index}>                          
                          <b>{d.dated}</b>{" | "} 
                          <Link to= {`/newsitem/${d.id}`}>{d.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })
          )}
        </div>
      )}
      <div className="flex-grow-1"></div>
    </div>
  );
}

export default News;
