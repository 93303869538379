import React from "react";
import RequiredMark from "./RequiredMark";
import Select from "react-select";

function Select2(props) {
  
  return (
    <div className={`form-group field-${props.name}`}>
      <label className="control-label" htmlFor={props.name}>
        {props.label}
        {props.reqCols.includes(props.name) && <RequiredMark />}
      </label>
      <Select
      isMulti ={props.multiple  === true ?true:false}
        id={props.name}
        className={` ${
        (props.errors && props.errors[props.name] ? " is-invalid " : "")+
          (props.multiple ? " basic-multi-select " : "")
        }`}
        name={props.name}
        defaultValue={props.value || props.prompt}
        value={props.value}
      
        onChange={(e) => {let e1 = {target:{name:props.name, value:e}}; props.handleInputChange(e1)}}
        aria-required={props.reqCols.includes(props.name) && "true"}
        options={props.listData}
  
      ></Select>

      <div className="invalid-feedback">
        {props.errors && props.errors[props.name]}
      </div>
    </div>
  );
}

export default Select2;
