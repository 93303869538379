import axiosInstance from "../helper/axios";

const SetupInterceptors = (logOut) => {
    
    axiosInstance.interceptors.response.use(
       function (response) {
           // Do something with response data
           return response;
       },
       function (error) {
           // Do something with response error
           if (error.response) {
                if ( error.response.status === 403) {
                    logOut();
                    return false;
                }
           }
           return Promise.reject(error);
      }
   );
};

export default SetupInterceptors;