import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { Link, useNavigate } from "react-router-dom";
import MoreDetailItem from "../components/MoreDetailItem";
import CustomField from "../components/CustomField";
import { notify } from "../helper/Helper";
const attApi = axiosInstance;

function Moredetail(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [moreData, setMoreData] = useState([]);
  const [fieldFormData, setFieldFormData] = useState([]);
  const [colNames, setColNames] = useState({});
  const [images, setImages] = useState({});
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [allowSave, setAllowSave] = useState(false);
  const [columns2, setColumns2] = useState({});
  const [reqCols, setReqCols] = useState({});
  const [customGroupId, setCustomGroupId] = useState("");
  const navigate = useNavigate();

  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    applicantStatus,
    getFillStatus,
  } = useContext(UserContext);

  useEffect(() => {
    setTitle("More Information");
    setOperation("Add More Information");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    if (applicantStatus) {
      if (applicantStatus.moreInfo) {
        setOperation("Update More Information");
      }
    }
  }, [applicantStatus, setOperation]);

  useEffect(() => {
    if (siteInfo !== null) {
      setLoading(true);
      attApi.get("get-other-info-needed").then((response) => {
        if (response.data.success === false) {
          navigate("/profile");
          setLoading(false);
          return;
        }
        setMoreData(response.data.steps);
        setColNames(response.data.meta);
        setAllowSave(response.data.allowSave);
        setColumns2(response.data.customFields.meta);
        setReqCols(response.data.customFields.required);
        setCustomGroupId(response.data.customGroupId);
        setFieldFormData(response.data.customFields.eModel);
        attApi.get("get-other-info").then((response2) => {
          if (response2.data.customData !== false) {
            setFieldFormData(response2.data.customData);
          }

          let OldData = response2.data.data;
          let mndata = [];
          let imgData = {};

          response.data.steps.forEach((md, index) => {
            if (OldData[md.step_did]) {
              let od = OldData[md.step_did];
              imgData[md.step_did] =
                od.path === ""
                  ? ""
                  : process.env.REACT_APP_BACKEND_URL2 + od.path;
              mndata.push({
                ...md,
                test_name: od.test_name,
                test_total: od.test_total,
                test_obtained: od.test_obtained,
                path: od.path,
              });
            } else {
              mndata.push(md);
            }
          });
          setMoreData(mndata);
          setImages(imgData);
          setLoading(false);
        });
      });
    }
  }, [siteInfo, navigate]);

  const onImageChange = (e, prefno) => {
    const { name } = e.target;
    const nextData = [];

    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const { name } = e.target;

      moreData.forEach((md, index) => {
        if (md.step_did === prefno) {
          nextData.push({ ...md, [name]: img });
        } else {
          nextData.push(md);
        }
      });
      let nimg = (window.URL || window.webkitURL).createObjectURL(img);
      setImages({ ...images, [prefno]: nimg });
      setMoreData(nextData);
    }
    const newErrors = {};
    Object.keys(errors).forEach(function (key2) {
      let oldNode = errors[key2];
      /* eslint-disable eqeqeq */
      if (key2 == prefno) {
        /* eslint-enable eqeqeq */
        newErrors[key2] = { ...oldNode, [name]: "" };
      } else {
        newErrors[key2] = oldNode;
      }
    });
    setErrors(newErrors);
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFieldFormData({
      ...fieldFormData,
      [name]: value,
    });
    // Clear the error message when user starts typing
    setErrors2({
      ...errors,
      [name]: "",
    });
  };

  const handleInputChange = (e, prefno) => {
    const { name, value } = e.target;
    const nextData = [];

    moreData.forEach((md, index) => {
      if (md.step_did === prefno) {
        nextData.push({ ...md, [name]: value });
      } else {
        nextData.push(md);
      }
    });

    setMoreData(nextData);
    const newErrors = {};
    Object.keys(errors).forEach(function (key2) {
      let oldNode = errors[key2];
      /* eslint-disable eqeqeq */
      if (key2 == prefno) {
        /* eslint-enable eqeqeq */
        newErrors[key2] = { ...oldNode, [name]: "" };
      } else {
        newErrors[key2] = oldNode;
      }
    });
    setErrors(newErrors);
  };

  function saveMoreInfo() {
    const newErrors = {};
    var formData2 = new FormData();
    var stepIds = [];
    var errorCount = 0;
    moreData.forEach((dataItem) => {
      stepIds.push(dataItem.step_did);
      var stepId = dataItem.step_did;

      let oldErrorNode = errors[stepId] ? errors[stepId] : {};
      if (dataItem.step_did === stepId) {
        let reqCols2 = [];
        if (dataItem.type === "2") {
          reqCols2.push("test_name");
          reqCols2.push("test_total");
          reqCols2.push("test_obtained");
        }
        if (dataItem.req_upload === true) {
          reqCols2.push("path");
        }
        if (reqCols2.length === 0) {
          reqCols2.push("test_name");
        }

        if (!newErrors[dataItem.step_did]) {
          newErrors[dataItem.step_did] = {};
        }
        reqCols2.forEach((key) => {
          let colName = colNames[key] ? colNames[key] : "Data";
          if (!dataItem[key]) {
            newErrors[dataItem.step_did][key] = colName + " cannot be blank.";
            errorCount++;
          }
        });

        if (dataItem.type === "2") {
          let testTotal = dataItem["test_total"];
          let testObtained = dataItem["test_obtained"];
          if (Number(testObtained) > Number(testTotal)) {
            newErrors[dataItem.step_did]["test_obtained"] =
              colNames["test_obtained"] +
              " can not be more than " +
              colNames["test_total"];
            errorCount++;
          }
          formData2.append(
            `ApplicantStepData[${stepId}][test_total]`,
            dataItem["test_total"]
          );
          formData2.append(
            `ApplicantStepData[${stepId}][test_obtained]`,
            dataItem["test_obtained"]
          );
        }
        formData2.append(
          `ApplicantStepData[${stepId}][path]`,
          dataItem["path"]
        );
        formData2.append(
          `ApplicantStepData[${stepId}][test_name]`,
          dataItem["test_name"]
        );
      } else {
        newErrors[dataItem.step_did] = oldErrorNode;
      }

      formData2.append("stepIds", stepIds);
    });
    if (newErrors && errorCount === 0) {
      attApi
        .post("/add-update-other-info", formData2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === false) {
              notify(res.data.message, "error");
              setErrors(res.data.errors);
            } else {
              notify(res.data.message, "success");
              getFillStatus();
            }
          } else {
            notify(res.data.message, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify("Error Validating the Form", "error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } else {
      setErrors(newErrors);
    }
  }
  function saveCustomData() {
    const newErrors = {};
    var formData2 = new FormData();
    var errorCount = 0;

    Object.keys(fieldFormData).forEach((key) => {
      formData2.append(`CustomData[${key}]`, fieldFormData[key]);
    });

    formData2.append(`CustomGroupId`, customGroupId);
    if (newErrors && errorCount === 0) {
      attApi
        .post("/add-custom-data", formData2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === false) {
              notify(res.data.message, "error");
              setErrors2(res.data.errors);
            } else {
              notify(res.data.message, "success");
              getFillStatus();
            }
          } else {
            notify(res.data.message, "error");
          }
        })
        .catch((err) => {
          notify("Error Validating the Form", "error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } else {
      setErrors2(newErrors);
    }
  }
  function saveData(e) {
    e.preventDefault();
    setLoading2(true);
    if (moreData.length > 0) {
      saveMoreInfo();
    }
    if (customGroupId !== "") {
      saveCustomData();
    }
    setLoading2(false);
  }

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <div className="card mb-2">
          <div className="card-body">
            {moreData.map((dataItem) => (
              <div className="mb-1" key={dataItem.step_did}>
                <MoreDetailItem
                  key={dataItem.step_did}
                  colNames={colNames}
                  data={dataItem}
                  handleInputChange={handleInputChange}
                  onImageChange={onImageChange}
                  errors={errors}
                  loading2={loading2}
                  img={images[dataItem.step_did]}
                  allowSave={allowSave}
                />
              </div>
            ))}

            {Object.keys(columns2).length > 0 &&
              Object.keys(columns2).map((key, i) => (
                <div className="mb-1" key={i}>
                  <CustomField
                    meta={columns2[key]}
                    reqCols={reqCols}
                    data={fieldFormData}
                    handleInputChange={handleInputChange2}
                    errors={errors2}
                    loading2={loading2}
                    allowSave={allowSave}
                  />
                </div>
              ))}
            {loading2 ? (
              <DataLoading />
            ) : (
              <>
                {moreData.length > 0 ? (
                  <div className="form-group mt-3">
                    {allowSave ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        name="save-button"
                        onClick={saveData}
                      >
                        {Trans("Save")}
                      </button>
                    ) : (
                      <></>
                    )}
                    {applicantStatus &&
                    applicantStatus.moreInfo === true  &&
                    applicantStatus.applied === false? (
                      <Link
                        className="btn btn-outline-primary ml-2"
                        to={"/choose"}
                      >
                        {Trans("Next")}
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Moredetail;
