import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../helper/axios";
import { UserContext } from "./UserContext";
import Trans from "../helper/Trans";
import { useMediaQuery } from "react-responsive";

const logoutApi = axiosInstance;
function Header(props) {
  const { user, setUser, logOut, siteInfo } = useContext(UserContext);
  const [showDp, setShowDp] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const showhideDp = () => {
    setShowDp(!showDp);
  };
  const logout = () => {
    logoutApi
      .post("/logout")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.loggedin === true) {
            window.localStorage.setItem(
              "user",
              JSON.stringify(res.data.params)
            );
            setUser(res.data.params);
          } else {
            logOut();
          }
        }
      })
      .catch((err) => {
        logOut();
      });
    setShowDp(false);
  };
  function changeLanguage(lang) {
    props.setCurrentLang(lang);
    setShowLanguage(false);
  }
  return (
    <>
      <div className="main-header pr-md-5" style={{ top: "0px" }}>
        {siteInfo ? (
          <>
            <div className="logo">
              <Link to="/">
                <img
                  className="logo-img ml-1"
                  src={process.env.REACT_APP_BACKEND_URL2 + siteInfo.uni_logo}
                  alt={siteInfo.uni_name}
                  title={siteInfo.uni_name}
                />
              </Link>
            </div>

            {isMobile ? (
              <div
                className="menu-toggle"
                onClick={() => {
                  props.setSideBarOpen(!props.sideBarOpen);
                }}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <></>
            )}

            {isMobile ? (
              <></>
            ) : (
                <div className=" ml-5 mobile-hide">
                  <h4>Directorate of Admission</h4>
                  <h5 className=" mobile-hide">{siteInfo.uni_name}</h5>
                </div>

            )}

            <div className="m-auto"></div>

            {isMobile ? (
              <></>
            ) : (
              <>
              
                {user ? (
                  <Link
                    to="/chat"
                    className="btn btn-outline-primary mr-3"
                    title={Trans("Chat with customer support")}
                  >
                    {props.chatCount === "0" ? (
                      <></>
                    ) : (
                      <span className="badge badge-danger">
                        {props.chatCount}
                      </span>
                    )}
                    <i className="i-Headset text-16 mr-2"></i>{" "}
                    {Trans("HELP DESK")}
                  </Link>
                ) : (
                  <></>
                )}
              </>
            )}
            <Link
              className="badge-top-container mr-3"
              role="button"
              title="Help"
              to="/faq"
            >
              <i className="text-muted header-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 122.88 116.6"
                >
                  <title>{Trans("FAQ")}</title>
                  <path
                    fill="#663399"
                    d="M17.72,45.43V21.12h16.8v5.32H24.21v4.18h9.28v5.31H24.21v9.5ZM16.67,0h66.5A16.7,16.7,0,0,1,99.84,16.67V51.36A16.7,16.7,0,0,1,83.17,68H45.46L20.16,89.78a2.81,2.81,0,0,1-4.62-2.31L16.89,68h-.22A16.7,16.7,0,0,1,0,51.36V16.67A16.7,16.7,0,0,1,16.67,0Zm93,24.65a16.42,16.42,0,0,1,13.26,16V77.85a16.37,16.37,0,0,1-16.33,16.32H106L107.34,114h0a2.46,2.46,0,0,1-4,2L77.89,93.53H38.26L54.74,76.77H95.82a13.9,13.9,0,0,0,13.85-13.86V25.75c0-.37,0-.74,0-1.1Zm-26.46-19H16.67a11.1,11.1,0,0,0-11,11.05V51.36a11.1,11.1,0,0,0,11.05,11h3.42a2.8,2.8,0,0,1,2.6,3L21.6,81.17l20.87-18a2.79,2.79,0,0,1,2-.81H83.16a11.1,11.1,0,0,0,11-11V16.67a11.1,11.1,0,0,0-11-11Zm-43,39.81h-7L41,21.12h8.87l7.88,24.31h-7L45.53,27.82h-.19L40.12,45.43Zm-1.31-9.59H52v4.94H38.81V35.84Zm29.35.57h5L75,38.74l3.08,3.55,4,5H76.37l-2.84-3.37-1.92-2.81-3.45-4.65Zm13.91-3.13a13.84,13.84,0,0,1-1.56,6.84,10.36,10.36,0,0,1-4.19,4.21,12.78,12.78,0,0,1-11.77,0,10.45,10.45,0,0,1-4.18-4.23,13.83,13.83,0,0,1-1.54-6.81,13.89,13.89,0,0,1,1.54-6.84,10.42,10.42,0,0,1,4.18-4.22,12.82,12.82,0,0,1,11.77,0,10.45,10.45,0,0,1,4.19,4.22,13.78,13.78,0,0,1,1.56,6.84Zm-6.68,0a10.35,10.35,0,0,0-.57-3.68,4.75,4.75,0,0,0-1.66-2.29,5.17,5.17,0,0,0-5.43,0,4.83,4.83,0,0,0-1.67,2.29,10.59,10.59,0,0,0-.56,3.68A10.63,10.63,0,0,0,66.06,37a4.8,4.8,0,0,0,1.67,2.28,5.12,5.12,0,0,0,5.43,0A4.72,4.72,0,0,0,74.82,37a10.38,10.38,0,0,0,.57-3.68Z"
                  />
                </svg>
              </i>
            </Link>
          </>
        ) : (
          <></>
        )}
        {user ? (
          <>
            
            <Link
              className="badge-top-container"
              role="button"
              title="Notifications"
              to="/notification"
            >
              {props.notiCount === "0" ? (
                <></>
              ) : (
                <span className="badge badge-danger" id="m_noti_4">
                  {props.notiCount}
                </span>
              )}
              <i className="i-Bell text-muted header-icon"></i>
            </Link>

            {props.showLanguages ? (
              <div className={"dropdown " + (showLanguage ? "show" : "")}>
                <i
                  className="text-muted header-icon"
                  id="dropdownMenuButton"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  onClick={() => {
                    setShowLanguage(!showLanguage);
                  }}
                  aria-expanded={showLanguage ? "true" : "false"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-translate"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"></path>
                    <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"></path>
                  </svg>
                </i>
                <div
                  className={"dropdown-menu " + (showLanguage ? "show" : "")}
                  aria-labelledby="dropdownMenuButton"
                  x-placement="bottom-start"
                  style={{
                    position: "absolute",
                    willChange: "transform",
                    top: "0px",
                    left: "0px",
                    transform: "translate3d(2px, 36px, 0px)",
                  }}
                >
                  {Object.keys(props.languages).map((e, i) => {
                    let item = props.languages[e];
                    return (
                      <span
                        className="dropdown-item"
                        key={i}
                        onClick={() => {
                          changeLanguage(item["id"]);
                        }}
                      >
                        {item["name"]}
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="header-part-right">
              <div className="dropdown">
                <div
                  className={
                    showDp
                      ? "user col align-self-end show"
                      : "user col align-self-end"
                  }
                >
                  <img
                    src={user.dp}
                    id="userDropdown"
                    alt=""
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={showhideDp}
                  />
                  <div
                    className={
                      showDp
                        ? "dropdown-menu dropdown-menu-right show"
                        : "dropdown-menu dropdown-menu-right"
                    }
                    aria-labelledby="userDropdown"
                  >
                    <div className="dropdown-header">
                      <i className="i-Lock-User mr-1"></i> {user.name}
                    </div>

                    <Link to="/changepassword" className="dropdown-item" onClick={showhideDp}>
                      {Trans("Change Password")}
                    </Link>

                    <a href="/#" className="dropdown-item" onClick={logout}>
                      {Trans("Sign out")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {siteInfo ? (
        <>
          <div className="horizontal-bar-wrap ">
            <div
              className={"header-topnav " + (props.sideBarOpen ? "open" : "")}
            >
              <div className="container-fluid">
                <div
                  className="topnav rtl-ps-none ps ps--active-y float-right"
                  id=""
                  data-perfect-scrollbar=""
                  data-suppress-scroll-x="true"
                >
                  <ul className="menu float-left">
                  <li>
                          <div>
                            <div>
                              <label className="toggle" htmlFor="drop-2">
                                {Trans("Home")}
                              </label>
                              <a
                                href={siteInfo.main_domain}
                              >
                                {Trans("Home")}
                              </a>
                            </div>
                          </div>
                        </li>
                    {props.menus &&
                      Object.keys(props.menus).map((e, i) => {
                        let mainMenu = props.menus[e];
                        return (
                          <li key={i}>
                            <div>
                              <div>
                                <label className="toggle" htmlFor="drop-2">
                                  {e}
                                </label>
                                <a href="#0">{e}</a>
                                <ul>
                                  {Object.keys(mainMenu).map((e2, i3) => {
                                    let subMenu = mainMenu[e2];
                                    return (
                                      <li key={i3}>
                                        <Link
                                          to={`/page/${subMenu.id}`}
                                          onClick={() => {
                                            props.setSideBarOpen(false);
                                          }}
                                        >
                                          {subMenu.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </li>
                        );
                      })}

                    {user ? (
                      <></>
                    ) : (
                      <>
                      {
                        siteInfo.allow_signup?<li>
                        <div>
                          <div>
                            <label className="toggle" htmlFor="drop-2">
                              {Trans("Signup")}
                            </label>
                            <Link
                              to="/signup"
                              onClick={() => {
                                props.setSideBarOpen(false);
                              }}
                            >
                              {Trans("Signup")}
                            </Link>
                          </div>
                        </div>
                      </li>:<></>
                      }
                        
                        
                        <li>
                          <div>
                            <div>
                              <label className="toggle" htmlFor="drop-2">
                                {Trans("Login")}
                              </label>
                              <Link
                                to="/login"
                                onClick={() => {
                                  props.setSideBarOpen(false);
                                }}
                              >
                                {Trans("Login")}
                              </Link>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
