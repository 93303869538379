import { toast } from "react-toastify";


export function notify(txt, type) {
  if (type === "success") {
    toast.success(txt);
  } else if (type === "error") {
    toast.error(txt);
  } else if (type === "info") {
    toast.info(txt);
  } else {
    toast(txt);
  }
  return;
}
