import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Validateotp from "./pages/Validateotp";
import Cookies from "js-cookie";
import axiosInstance from "./helper/axios";
import { UserContext } from "./components/UserContext";
import Index from "./pages/Index";
import ProtectedRoutes from "./helper/ProtectedRoutes";
import Privacy from "./pages/Privacy";
import Breadcrumb from "./components/Breadcumb";
import Page404 from "./pages/Page404";
import ChangePassword from "./pages/Applicant/ChangePassword";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetupInterceptors from "./helper/SetupInterceptor";
import { Helmet } from "react-helmet";

import Notifications from "./pages/Notifications";
import Choose from "./pages/Choose";
import Profile from "./pages/Profile";
import Sidemenu from "./components/Sidemenu";
import Guardian from "./pages/Guardian";
import Contact from "./pages/Contact";
import Awards from "./pages/Awards";
import Employment from "./pages/Employment";
import Moredetail from "./pages/Moredetail";
import Apply from "./pages/Apply";
import Academic2 from "./pages/Academic2";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import Faq from "./pages/Faq";
import Help from "./pages/Help";
import Chat from "./pages/Chat";
import News from "./pages/News";
import NewsItem from "./pages/NewsItem";
import Terms from "./pages/Terms";
import Page from "./pages/Page";
import { useMediaQuery } from "react-responsive";
import Test from "./pages/Test";
import Category from "./pages/Category";
import ReactGA from 'react-ga4';
import Scholarship from "./pages/Scholarship";

const pingApi = axiosInstance;

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  ReactGA.initialize('G-LZZKPZDT6Z');
  const refreshUser = () => {
    pingApi
      .get("/logedin")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.loggedin === true) {
            setUser(res.data.params)
          }
        }
      })
  }
  const logOut = () => {
    window.localStorage.clear("user");
    setUser(null);
  }
  function NavigateFunctionComponent(props) {
    // let navigate = useNavigate();
    const [ran, setRan] = useState(false);
    if (!ran) {
      SetupInterceptors(logOut);
      setRan(true);
    }
    return <></>;
  }

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  let luser = window.localStorage.getItem("user");

  if (luser != null) {
    luser = JSON.parse(luser);
  }
  let lSite = window.localStorage.getItem("site");

  if (lSite != null) {
    lSite = JSON.parse(lSite);
  }
  const [user, setUser] = useState(luser);
  const [siteInfo, setSiteInfo] = useState(null);
  const [applicantStatus, setApplicantStatus] = useState(null);
  const [title, setTitle] = useState('');
  const [operation, setOperation] = useState('');
  const [plink, setPLink] = useState([]);
  const [siteError, setSiteError] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState("en");
  const [showLanguages, setShowLanguages] = useState(false);
  const [languages, setLanguages] = useState({});
  const [menus, setMenus] = useState({});
  const [chatCount, setChatCount] = useState("0");

  const [relations, setRelations] = useState({});
  const [religions, setReligions] = useState({});
  const [translation, setTranslation] = useState({ home: 'Home' });
  const [notiCount, setNotiCount] = useState("0");
  const [menuNames, setMenuNames] = useState({
    category: 'Category Selection', home: 'Home', profile: 'Personal Information', contact: 'Contact Information',
    guardian: 'Guardian Information', academic: 'Academic Detail', moreinfo: 'More Information',
    award: 'Awards & Honors', employment: 'Employment Detail', choose: 'Degree Options',
    preference: "Preferences", scholarship: "Scholarship Informarion"
  });


  useEffect(() => {
    let ss = Cookies.get("admission-web-api");
    async function fetchData() {
      if (ss === undefined || ss === null || ss === "") {
        pingApi.get("ping").then((response) => {
          Cookies.set("admission-web-api", response.data, {
            secure: true,
            sameSite: "None",
          });
        });
      }
      let currentUrl = window.location.hostname;
      var formData = new FormData();
      formData.append("url", currentUrl);
      const response = await pingApi.post("get-application-id", formData);
      if (response.data.success === false) {
        setSiteInfo(null);
        toast.error(response.data.message);
        setSiteError(response.data.message)
      } else {
        setSiteInfo(response.data.university);
        window.localStorage.setItem("site", JSON.stringify(response.data.university));
        setShowLanguages(response.data.showLanguage);
        setLanguages(response.data.languages);
        setMenus(response.data.menu);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    if (user !== null) {
      pingApi.get("get-guardian-relations").then((response) => {
        setRelations(response.data.data);
      });
      pingApi.get("get-religions").then((response) => {
        setReligions(response.data.data);
      });
    }
  }, [user]);
  useEffect(() => {
    var formData = new FormData();
    formData.append("language", currentLang);
    pingApi.post("set-language", formData).then((response) => {
      if (response.data.success === true) {
        setTranslation(response.data.transation);
        setMenuNames(response.data.menuNames);
      }
    });
  }, [currentLang]);

  const getFillStatus = useCallback(() => {
    if (user !== null) {
      pingApi.get("get-applicant-fill-status").then((response) => {
        let status = response.data.data;
        status.moreInfoReq = response.data.data2.moreInfoReq;
        status.scholarshipInfoReq = response.data.data2.scholarshipInfoReq;
        status.applied = response.data.data2.applied;
        status.application_submitted = response.data.data2.application_submitted;
        setApplicantStatus(status);
      });
    }
  }, [user])

  useEffect(() => {
    getFillStatus();
  }, [getFillStatus]);

  const getNotificationCount = useCallback(() => {
    if (user !== null) {
      pingApi.get("/notificationcount").then((res) => {
        if (res.status === 200) {
          setNotiCount(res.data);
        }
      });

      pingApi.get("chat-count").then((response) => {
        setChatCount(response.data);
      });

    }
  }, [user]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      getNotificationCount();
    }
  }, [getNotificationCount]);


  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  let applicationTitle = translation && translation['Admission Pro'] ? translation['Admission Pro'] : 'Admission Pro';
  const contexts = { user, siteInfo, applicantStatus, getFillStatus, setUser, setTitle, setOperation, setPLink, logOut, refreshUser, currentLang, translation };
  return (
    <>
      <Helmet htmlAttributes={{
        lang: currentLang,
      }}
      >
        <title >{applicationTitle}</title>
        <html lang={currentLang} dir={currentLang === 'ar' ? "rtl" : "ltr"}></html>
        <body className={currentLang === 'ar' ? "text-left" : "text-left"}></body>
      </ Helmet>
      <UserContext.Provider value={contexts}>
        <Router>
          {<NavigateFunctionComponent />}
          <div className={"app-admin-wrap layout-horizontal-bar"}>

            <Header setSideBarOpen={setSideBarOpen} chatCount={chatCount} sideBarOpen={sideBarOpen} notiCount={notiCount} setNotiCount={setNotiCount} setCurrentLang={setCurrentLang} languages={languages} showLanguages={showLanguages} menus={menus} />

            <div className={"main-content-wrap d-flex flex-column"}>
              <div className="main-content" >
                {
                  siteInfo && isMobile ?
                    <div className="mb-2">
                      <h4 className="text-center">Directorate of Admission</h4>
                      <h5 className="text-center" >{siteInfo.uni_name}</h5>
                      {
                        user ? <p className="text-center"> <Link
                          to="/chat"
                          className="btn btn-outline-primary mr-2"
                          title={"Chat with customer support"}
                        >
                          {chatCount === "0" ? (
                            <></>
                          ) : (
                            <span className="badge badge-danger">
                              {chatCount}
                            </span>
                          )}
                          <i className="i-Headset text-16 mr-2"></i>{" "}
                          {"HELP DESK / Contact for Help"}
                        </Link></p> :
                          <p className="text-center">
                            {siteInfo && siteInfo.allow_signup ?
                              <Link to="/signup" className="btn btn-primary mr-1">Signup</Link>
                              : <></>
                            }
                            <Link to="/login" className="btn btn-success ml-1">Login</Link>
                          </p>
                      }
                    </div> : <></>
                }
                <div className="row">
                  {
                    user ? <div className="col-md-3 col-sm-2 col-2 mb-3">
                      <Sidemenu sideBarOpen={sideBarOpen} menuNames={menuNames} setSideBarOpen={setSideBarOpen} />
                    </div> : <></>
                  }

                  <div className={user ? "col-md-9 col-sm-10 col-10" : "col-12"}>
                    <Breadcrumb title={title} operation={operation} plink={plink} menuNames={menuNames} />

                    {siteError !== '' ? (
                      <div className="text-center mt-3">

                        <img className="logo" src="/imgs/logo.png" alt="Admission Pro" />
                        <h3 className="mt-5 ">{siteError}</h3>
                      </div>
                    ) : (

                      <>
                        <ToastContainer />
                        <Routes>
                          <Route path="/" exact element={<Index getNotificationCount={getNotificationCount} />} />
                          <Route path="/login" exact element={<Login />} />
                          <Route path="/signup" exact element={<Signup />} />
                          <Route path="/validateotp" exact element={<Validateotp />} />
                          <Route path="/resetpassword" exact element={<ResetPasswordRequest />} />
                          <Route path="/faq" exact element={<Faq />} />
                          <Route path="/help" element={<Help />} />
                          <Route path="/news" element={<News />} />
                          <Route path="/terms" element={<Terms />} />
                          <Route path="/newsitem/:newsid" element={<NewsItem />} />
                          <Route path="/page/:pageid" element={<Page />} />
                          <Route path="/test" element={<Test />} />
                          <Route element={< ProtectedRoutes />}>
                            <Route path="/category" element={<Category />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="/notification" element={<Notifications setNotiCount={setNotiCount} />} />
                            <Route path="/profile" element={<Profile religions={religions} />} />
                            <Route path="/choose" element={<Choose />} />
                            <Route path="/guardian" element={<Guardian religions={religions} />} />
                            <Route path="/contact" element={<Contact relations={relations} />} />
                            <Route path="/academic" element={<Academic2 />} />
                            <Route path="/moreinfo" element={<Moredetail />} />
                            <Route path="/award" element={<Awards />} />
                            <Route path="/employment" element={<Employment />} />
                            <Route path="/apply" element={<Apply />} />
                            <Route path="/scholarship" element={<Scholarship />} />

                            <Route path="/chat" element={<Chat />} />
                          </Route>
                          <Route path='*' element={<Page404 />} />
                        </Routes>
                      </>
                    )}
                    <div className="flex-grow-1"></div>

                  </div>

                </div>
              </div>
              <Footer />
            </div>
          </div>
        </Router>
      </UserContext.Provider>
    </>
  );
}

export default App;
