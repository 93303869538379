import React from "react";
import DropDownList from "./DropDownList";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";

function CustomField(props) {
  const type = props.meta.type;
  const type2 = props.meta.child === false ? "" : props.meta.child.type;
  const colName = props.meta.colName;
  const colName2 = props.meta.child === false ? "" :props.meta.child.colName;

  return (
    <div>
      <div className="row">
        {type === "L" ? (
          <div className="col-md-6">
            <DropDownList
              name={props.meta.colName}
              label={props.meta.label}
              value={props.data[colName]}
              handleInputChange={props.handleInputChange}
              reqCols={props.reqCols}
              prompt={"-Select-"}
              listData={props.meta.dropdown}
              errors={props.errors}
            />
          </div>
        ) : (
          <>
            {type === "T" ? (
              <div className="col-md-12">
                <TextInput
                  name={props.meta.colName}
                  label={props.meta.label}
                  value={props.data[colName]}
                  handleInputChange={props.handleInputChange}
                  reqCols={props.reqCols}
                  errors={props.errors}
                  length={props.meta.length}
                />
              </div>
            ) : (
              <>
                {type === "N" ? (
                  <div className="col-md-6">
                    <NumberInput
                      name={props.meta.colName}
                      label={props.meta.label}
                      value={props.data[colName]}
                      handleInputChange={props.handleInputChange}
                      reqCols={props.reqCols}
                      errors={props.errors}
                      length={props.meta.length}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}

        {props.meta.child === false ? (
          <></>
        ) : (
          <>
            {
              props.data[colName] &&
            props.data[colName] === props.meta.child.exp_val ? (
              <>
                {type2 === "L" ? (
                  <div className="col-md-6">
                    <DropDownList
                      name={props.meta.child.colName}
                      label={props.meta.child.label}
                      value={props.data[colName2]}
                      handleInputChange={props.handleInputChange}
                      reqCols={props.reqCols}
                      prompt={"-Select-"}
                      listData={props.meta.child.dropdown}
                      errors={props.errors}
                    />
                  </div>
                ) : (
                  <>
                    {type2 === "T" ? (
                      <div className="col-md-6">
                        <TextInput
                          name={props.meta.child.colName}
                          label={props.meta.child.label}
                          value={props.data[colName2]}
                          handleInputChange={props.handleInputChange}
                          reqCols={props.reqCols}
                          errors={props.errors}
                          length={props.meta.child.length}
                        />
                      </div>
                    ) : (
                      <>
                        {type2 === "N" ? (
                          <div className="col-md-6">
                            <NumberInput
                              name={props.meta.child.colName}
                              label={props.meta.child.label}
                              value={props.data[colName2]}
                              handleInputChange={props.handleInputChange}
                              reqCols={props.reqCols}
                              errors={props.errors}
                              length={props.meta.child.length}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CustomField;
