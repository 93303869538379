import React ,{ useContext  } from "react";
import { Outlet} from 'react-router-dom';
import { UserContext } from "../components/UserContext";
import Login from "../pages/Login";

const useAuth = () =>{  
    const {user } = useContext(UserContext);
    const user1 = {loggedIn: user ==null?false:true};
    return user1 && user1.loggedIn;
}

const ProtectedRoutes = ()=>{
    const isAuth = useAuth();
    return isAuth? <Outlet /> : <Login/>;
}
export default ProtectedRoutes;