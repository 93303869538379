import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";
import DropDownList from "../components/DropDownList";
import MaskedInput2 from "../components/MaskedInput2";
import TextArea2 from "../components/TextArea2";
import TextInput from "../components/TextInput";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";
import NumberInput from "../components/NumberInput";
const attApi = axiosInstance;
function Guardian(props) {
  const [loading, setLoading] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formMeta, setFormMeta] = useState([]);
  const [guardian, setGuardian] = useState([]);
  const [errors, setErrors] = useState({});
  const [reqCols, setReqCols] = useState([]);
  const [allowSave, setAllowSave] = useState(false);
  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    applicantStatus,
    getFillStatus,
  } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Gaurdian Information");
    setOperation("Add Gaurdian Information");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    if (applicantStatus) {
      if (applicantStatus.guardianInfo) {
        setOperation("Update Gaurdian Information");
      }
    }
  }, [applicantStatus, setOperation]);

  const fatherStatus = { A: "Alive", D: "Deceased" };
  const fatherOccupation = {
    "Public Servant": "Public Servant",
    Business: "Business",
    "Retired Public Servant": "Retired Public Servant",
    "Private Service": "Private Service",
    "Retired from Private Service": "Retired from Private Service",
    Unemployed: "Unemployed",
  };
  const motherOccupation = {
    "House Wife": "House Wife",
    "Public Servant": "Public Servant",
    Business: "Business",
    "Retired Public Servant": "Retired Public Servant",
    "Private Service": "Private Service",
    "Retired from Private Service": "Retired from Private Service",
  };

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("get-guardian-info").then((response) => {
        if (response.data.success === false) {
          navigate("/profile");
          setLoading(false);
          return;
        }
        setFormData(response.data.data);
        setFormMeta(response.data.meta);
        setReqCols(response.data.required);
        setAllowSave(response.data.allowSave);
        attApi.get("get-guardian-relations").then((response) => {
          setGuardian(response.data.data);
          setFormReady(true);
          setLoading(false);
        });
      });
    }
  }, [siteInfo, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function saveData(e) {
    let btnName = e.target.name;
    setLoading2(true);
    e.preventDefault();
    var formData2 = new FormData();
    const newErrors = {};
    reqCols.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    if (formData.father_status === "D" && formData.g_name === "") {
      newErrors.g_name = `${formMeta.g_name} cannot be blank.`;
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      setLoading2(false);
      return;
    }

    setLoading2(true);

    Object.keys(formData).forEach((key) => {
      formData2.append(`Guardian[${key}]`, formData[key]);
    });

    attApi
      .post("/add-guardian-info", formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validatingsthe Form", "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
            if (btnName === "savenext-button") {
              navigate(`/academic`);
            }
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        setLoading(false);
        setLoading2(false);
      });
  }
  return (
    <div className="">
      {loading || formReady === false ? (
        <DataLoading />
      ) : (
        <>
          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <DropDownList
                    name="father_status"
                    label={formMeta.father_status}
                    value={formData.father_status}
                    listData={fatherStatus}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    name="g_name"
                    label={formMeta.g_name}
                    value={formData.g_name}
                    length={100}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <DropDownList
                    name="g_relation"
                    label={formMeta.g_relation}
                    value={formData.g_relation}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    prompt={"-Choose Relation-"}
                    listData={guardian}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <MaskedInput2
                    mask="11111-1111111-1"
                    name="g_cnic"
                    label={formMeta.g_cnic}
                    value={formData.g_cnic}
                    length={20}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <DropDownList
                    name="g_occupation"
                    label={formMeta.g_occupation}
                    value={formData.g_occupation}
                    listData={fatherOccupation}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    prompt={Trans("- Select -")}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    name="father_designation"
                    label={formMeta.father_designation}
                    value={formData.father_designation}
                    length={50}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="Manager"
                  />
                </div>
                <div className="col-md-4">
                  <DropDownList
                    name="g_religion"
                    label={formMeta.g_religion}
                    value={formData.g_religion}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    prompt={"-Select-"}
                    listData={props.religions}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    name="g_phone"
                    label={formMeta.g_phone}
                    value={formData.g_phone}
                    length={20}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="03003434543"
                  />
                </div>

                <div className="col-md-4">
                  <TextArea2
                    name="g_address"
                    label={formMeta.g_address}
                    value={formData.g_address}
                    length={20}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="House No. 345 Sector 2, Ichra Road Lahore"
                  />
                </div>
                
                <div className="col-md-4">
                  <NumberInput
                    name="family_members"
                    label={formMeta.family_members}
                    value={formData.family_members}
                    min={0}
                    max={50}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <DropDownList
                    name="mother_occupation"
                    label={formMeta.mother_occupation}
                    value={formData.mother_occupation}
                    listData={motherOccupation}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    prompt={Trans("- Select -")}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    name="mother_designation"
                    label={formMeta.mother_designation}
                    value={formData.mother_designation}
                    length={50}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="Vice President"
                  />
                </div>

                <div className="col-md-4">
                  <NumberInput
                    name="monthly_income"
                    label={formMeta.monthly_income}
                    value={formData.monthly_income}
                    min={0}
                    max={99999999}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                  />
                </div>
                
              </div>

              <div className="form-group mt-3">
                {loading2 ? (
                  <DataLoading />
                ) : (
                  <>
                    {allowSave ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        name="save-button"
                        onClick={saveData}
                      >
                        {Trans(
                          applicantStatus &&
                            applicantStatus.guardianInfo === true
                            ? "Update"
                            : "Save"
                        )}
                      </button>
                    ) : (
                      <></>
                    )}
                    {applicantStatus &&
                    applicantStatus.guardianInfo === true &&
                    applicantStatus.applied === false ? (
                      <button
                        type="submit"
                        className="btn btn-outline-secondary m-2"
                        name="savenext-button"
                        onClick={saveData}
                      >
                        {Trans("Next")}
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Guardian;
