import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import Trans from "../helper/Trans";

function Breadcrumb(props) {
  const { user } = useContext(UserContext);
  return (
    < div className={user?"":"container"}>
      {props.title ? (
        <>
          <div className="breadcrumb ul-widget4__users">
            <h1>{Trans(props.title)}</h1>
            <ul className="float-right">
              <li>
                <Link to="/">{props.menuNames.home}</Link>
              </li>
              {props.plink.length === 0 ? (
                <></>
              ) : (
                props.plink.map(function (d, index) {
                  return (
                    <li key={index}>
                      <Link to={d.link}>{Trans(d.name)}</Link>
                    </li>
                  );
                })
              )}
              <li>{Trans(props.operation)}</li>
            </ul>
          </div>
          <div className="separator-breadcrumb border-top mb-2" />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Breadcrumb;
