import React, { useCallback, useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import AwardForm from "../components/AwardForm";
import AwardList from "../components/AwardList";
const attApi = axiosInstance;

function Award(props) {
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [eModel, setEModel] = useState({});
  const [listData, setListData] = useState({});
  const [formMeta, setFormMeta] = useState({});
  const [reqCols, setReqCols] = useState([]);
  const [awardTypes, setAwardTypes] = useState({});
  const [allowSave, setAllowSave] = useState(false);
  const navigate = useNavigate();

  const { setTitle, setOperation, setPLink, siteInfo,applicantStatus } =
    useContext(UserContext);

  useEffect(() => {
    setTitle("Awards & Honors");
    setOperation("Awards & Honors");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi.get("get-award-meta").then((response) => {
        if(response.data.success === false){
          navigate('/profile');
          setLoading(false);
          return;
        }
        setFormMeta(response.data.meta);
        setReqCols(response.data.required);
        setEModel(response.data.model);
        setAllowSave(response.data.allowSave);
        attApi
        .get("get-award-types")
        .then((response) => {
          setAwardTypes(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      });
    }
  }, [siteInfo , navigate]);

  function refreashData() {
    attApi.get("get-awards").then((response) => {
      setListData(response.data.data);
      setLoading(false);
    });
  }
  useEffect(() => {
    refreashData();
  }, [formMeta]);

  useEffect(() => {
    if (listData.length === 0) {
      setIsForm(true);
      setIsNew(true);
      setFormData(eModel);
    }
  }, [listData, eModel]);

  useEffect(() => {
    if (isForm === true) {
      if (isNew === true) {
        setOperation("Add Award & Honor");
      } else {
        setOperation("Update Award & Honor");
      }
    } else {
      setOperation("Awards & Honors List");
    }
  }, [isNew, isForm, setOperation]);

  function addNew() {
    setIsForm(true);
    setIsNew(true);
    setFormData(eModel);
  }
  const updateData = useCallback((item) => {
    setIsForm(true);
    setIsNew(false);
    setFormData(item);
  }, []);

  return (
    <div className="">
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <p>
            {Trans(
              "Note: Provide details about your Position/Rank, Medals, Certificates etc."
            )}
          </p>
          {isForm ? (
            <AwardForm
              awardTypes={awardTypes}
              formData={formData}
              formMeta={formMeta}
              reqCols={reqCols}
              isNew={isNew}
              setIsForm={setIsForm}
              listLength={listData.length}
              refreashData={refreashData}
              allowSave={allowSave}
            />
          ) : (
            <>
              {listData.length > 0 ? (
                <div className="card mb-4">
                  <div className="card-body">
                    {allowSave ? (
                      <button
                        className="btn btn-success ml-2 mb-2"
                        onClick={addNew}
                      >
                        {Trans("Add")}
                      </button>
                    ) : (
                      <></>
                    )}
                    {applicantStatus && applicantStatus.degreeSelected ? (
                      <Link
                        className="btn btn-outline-primary ml-2 mb-2"
                        to={
                          siteInfo.show_employement === "1"
                            ? "/employment"
                            : "/choose"
                        }
                      >
                        {Trans("Next")}
                      </Link>
                    ) : (
                      <></>
                    )}
                    {listData.length === 0 ? (
                      <></>
                    ) : (
                      <AwardList
                        data={listData}
                        formMeta={formMeta}
                        refreashData={refreashData}
                        updateData={updateData}
                        allowSave={allowSave}
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Award;
