import React from "react";
import RequiredMark from "./RequiredMark";

function NumberInput(props) {
   
  return (
    <div className={`form-group field-${props.name}`}>
      <label className="control-label" htmlFor={props.name}>
        {props.label}
        {props.reqCols.includes(props.name) && (
          <RequiredMark/>
        )}
      </label>
      <input
        type="number"
        id={props.name}
        className={`form-control ${ props.errors&&props.errors[props.name] ? "is-invalid" : ""}`}
        name={props.name}
        value={props.value}
        maxLength={props.length}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={props.handleInputChange}
        aria-required= {props.reqCols.includes(props.name) && "true"}
        placeholder={props.placeholder}
      />

      <div className="invalid-feedback">{props.errors&&props.errors[props.name]}</div>
    </div>
  );
}

export default NumberInput;
