import React, { useEffect, useState } from "react";
import axiosInstance from "../helper/axios";
import TextInput from "./TextInput";
import Trans from "../helper/Trans";
import DataLoading from "./DataLoading";
import { notify } from "../helper/Helper";

const attApi = axiosInstance;
function DocObjectionResponse(props) {
  const [loading, setLoading] = useState(false);
  const [colName, setColName] = useState("Message");
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({app_response_form:''});
  const [reqCols] = useState(["app_response_doc"]);
  const handleInputChange = (e) => {
    const {name, value } = e.target;
    setMsg(value);
    setErrors({
        ...errors,
        [name]: "",
      });
  };

  function saveData(e) {
    const newErrors = {};

    reqCols.forEach((key) => {
        console.log(key);
        if (msg === '') {
          newErrors[key] = `${colName} cannot be blank.`;
        }
      });
    
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);
    var formData2 = new FormData();
    formData2.append(`Application[app_response_doc]`, msg);

    attApi
      .post("/update-doc-objection?id=" + props.id, formData2, {})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            setErrors(res.data.errors);
            notify("Error Validating the Form", "error");
          } else {
            props.refreshAppData();
            notify(res.data.message, "success");
            props.setOpenDocResModal(false);
            
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    if (props.id !== "") {
      attApi
        .get("get-applicant-doc-objection-response?id=" + props.id)
        .then((response) => {
          setColName(response.data.meta);
          setMsg(response.data.data);
        });
    }
  }, [props.id]);
  return (
    <div className="mt-3">
      <TextInput
        name="app_response_doc"
        label={colName}
        value={msg}
        length={100}
        handleInputChange={handleInputChange}
        reqCols={reqCols}
        errors={errors}
      />

      {loading ? (
        <DataLoading />
      ) : (
        <>
          <button className="btn btn-primary" onClick={saveData}>
            {Trans("Reply")}
          </button>
          <button
            className="btn btn-danger ml-2"
            onClick={() => {
              props.setOpenDocResModal(false);
            }}
          >
            {Trans("Close")}
          </button>
        </>
      )}
    </div>
  );
}

export default DocObjectionResponse;
