import React from "react";

function DataLoading(props) {
  let loadTxt = "loading data..........";
  if(props.loadTxt){
    loadTxt = props.loadTxt
  }
  return (
    <div className="spinner-container text-center">
      <div className="loading-spinner " style={{margin: "auto"}}></div>
      {loadTxt}
    </div>
  );
}

export default DataLoading;
