import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import DropDownList from "../components/DropDownList";
import Select2 from "../components/Select2";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";
import { useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";

const loginApi = axiosInstance;
function Category(props) {
  let initError = {
    cat: "",
    campuses: "",
  };
  let initData = {
    cat: "",
    campuses: "",
  };
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [errorText, setErrorText] = useState(initError);
  const [formdata, setFormData] = useState(initData);
  const [reqCols2, setReqCols2] = useState([]);
  const [cats, setCats] = useState({});
  const [campuses, setCampuses] = useState({});
  const [quota, setQuota] = useState({});
  const [instructions, setInstructions] = useState("");
  const [formQuota, setFormQuota] = useState({});
  const [errors2, setErrors2] = useState({});
  const [allowSave, setAllowSave] = useState(false);
 // const [allowMultiple, setAllowMultiple] = useState(false);

 const reqCols = ["cat", "campuses"];
  const Linkify = ({ children }) => {
    const isUrl = (word) => {
      const urlPattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
      return word.match(urlPattern);
    };

    const addMarkup = (word) => {
      return isUrl(word)
        ? `<a href="${word}" target="_blank">${word}</a>`
        : word;
    };

    const words = children.split(" ");
    const formatedWords = words.map((w, i) => addMarkup(w));
    const html = formatedWords.join(" ");
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };
  const handleInputChange4 = (e, quotaId) => {
    const { name, value } = e.target;
    let oldNode = formQuota;
    const newData = { ...oldNode, [name]: value };
    setFormQuota(newData);
  };
  let navigate = useNavigate();
  const [formMeta] = useState({
    cat: "Applying for Program",
    campuses: "Apply in Campuses",
  });
  const { setTitle, setOperation, setPLink, siteInfo, getFillStatus } =
    useContext(UserContext);

  useEffect(() => {
    setTitle("Category Selection");
    setOperation("Select Category");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  function handelChange(e) {
    let value = e.target.value;
    let inputName = e.target.name;

    if (inputName === "cat") {
      setFormData({ ...formdata, [inputName]: value, campuses: "" });
    } else {
      setFormData({ ...formdata, [inputName]: value });
    }
    setErrorText(initError);
  }
  useEffect(() => {
    loginApi.get("get-quota?catId=" + formdata.cat).then((response) => {
      setQuota(response.data.data.quota);
      setCampuses(response.data.data.campuses);
      setReqCols2(response.data.data.reqCol);
     // if (response.data.data.campuses.length > 1) {
     //   setAllowMultiple(true);
     // }
    });
  }, [formdata.cat]);

  useEffect(() => {
    loginApi.get("get-cat-selection").then((response) => {
      let nfd = {};
      nfd.cat = response.data.data.cat;
      nfd.campuses = response.data.data.campuses;
      setFormData(nfd);
      setFormQuota(response.data.data.quota);
    });
  }, [cats]);

  useEffect(() => {
    if (siteInfo) {
      setLoading(true);
      loginApi
        .get("categories?id=" + siteInfo.app_id)
        .then((response) => {
          setCats(response.data.data.categories);

          setInstructions(response.data.data.instructions);
          setAllowSave(response.data.allowSave);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteInfo]);

  function save(e) {
    e.preventDefault();

    const newErrors = {};
    reqCols.forEach((key) => {
      if (!formdata[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    setErrorText(newErrors);
    const newErrors2 = {};
    reqCols2.forEach((key) => {
      if (!formQuota[key]) {
        newErrors2[key] = `${formQuota[key]} cannot be blank.`;
      }
    });
    setErrors2(newErrors2);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form (1)", "error");
      return;
    }
    if (Object.keys(newErrors2).length > 0) {
      notify("Error Validating the Form (2)", "error");
      return;
    }

    setLoading2(true);
    var formData = new FormData();

    formData.append("Category[cat]", formdata.cat);
    formdata.campuses.map(function (d) {
      formData.append("Category[campuses][]", d.value);
      return 1;
    });

    Object.keys(formQuota).forEach((key) => {
      formData.append(`ProvisionalDegreeChoice[quota][${key}]`, formQuota[key]);
    });

    loginApi
      .post("/save-category", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            notify(res.data.message, "error");
            setErrorText(res.data.errors);
          } else {
            setErrorText(initError);
            notify(res.data.message, "success");
            getFillStatus();
            navigate("/profile");
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        setLoading2(false);
      });
  }
  return (
    <div className="card">
      <div className="card-body">
        <form>
          {loading ? (
            <DataLoading />
          ) : (
            <>
              <p>
                <Linkify>{instructions}</Linkify>
              </p>
              <DropDownList
                name="cat"
                label={formMeta.cat}
                value={formdata.cat}
                handleInputChange={handelChange}
                reqCols={reqCols}
                listData={cats}
                errors={errorText}
                prompt={"- Select -"}
              />

              <Select2
                name="campuses"
                label={formMeta.campuses}
                value={formdata.campuses}
                handleInputChange={handelChange}
                reqCols={reqCols}
                prompt={"-Select-"}
                listData={campuses}
                errors={errorText}
                multiple
              />

              {Object.keys(quota).length > 0 ? (
                <h4 className="mt-4">{Trans("Select Catgories")}</h4>
              ) : (
                <></>
              )}
              {Object.keys(quota).map((e, i) => {
                const colName = "quota_" + e;
                return (
                  <div key={i + 50}>
                    {quota[e].type === "R" ? (
                      <div className="row" >
                        <div className="col-md-6">
                          <DropDownList
                            name={colName}
                            label={quota[e].name}
                            value={formQuota[colName]}
                            handleInputChange={handleInputChange4}
                            reqCols={reqCols2}
                            prompt={"-Select-"}
                            listData={quota[e].categories}
                            errors={errors2}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
              {Object.keys(quota).length > 0 ? (
                <>
                  <h5 className="mt-4">{Trans("Select Optional Catgories")}</h5>
                  <p>{Trans("Leave blank if not eligiable")}</p>
                </>
              ) : (
                <></>
              )}
              {Object.keys(quota).map((e, i) => {
                const colName = "quota_" + e;
                return (
                  <div key={i + 100}>
                    {quota[e].type === "N" ? (
                      <div className="row" >
                        <div className="col-md-6">
                          <DropDownList
                            name={colName}
                            label={quota[e].name}
                            value={formQuota[colName]}
                            handleInputChange={handleInputChange4}
                            reqCols={reqCols2}
                            prompt={"-Select-"}
                            listData={quota[e].categories}
                            errors={errors2}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {allowSave ? (
            <div className="form-group mt-3">
              {loading2 ? (
                <DataLoading loadTxt={Trans("Saving.....")} />
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    name="login-button"
                    onClick={save}
                  >
                    {Trans("Save")}
                  </button>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
}

export default Category;
