import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import DataLoading from "../components/DataLoading";
import DropDownList from "../components/DropDownList";
import TextArea2 from "../components/TextArea2";
import TextInput from "../components/TextInput";
import Select2 from "../components/Select2";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";
import CountriesList from "../helper/CountriesList";
const attApi = axiosInstance;

function Contact(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [formData, setFormData] = useState({
    country: { value: "PK", label: "Pakistan" },
    emergency_no: "",
  });
  const [formMeta, setFormMeta] = useState({});
  const [reqCols, setReqCols] = useState([]);

  const [cities, setCities] = useState({});
  const [provinces, setProvinces] = useState({});
  const [divisions, setDivisions] = useState({});
  const [districts, setDistricts] = useState({});
  const [allowSave, setAllowSave] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const YesNoList = { 0: Trans("No"), 1: Trans("Yes") };
  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    applicantStatus,
    getFillStatus,
  } = useContext(UserContext);
  useEffect(() => {
    setTitle("Contact Information");
    setOperation("Add Contact Information");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    if (applicantStatus) {
      if (applicantStatus.contactInfo) {
        setOperation("Update Contact Information");
      }
    }
  }, [applicantStatus, setOperation]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi
        .get("get-contact-info")
        .then((response) => {
          if (response.data.success === false) {
            navigate("/profile");
            setLoading(false);
            return;
          }
          setFormData(response.data.data);
          setFormMeta(response.data.meta);
          setReqCols(response.data.required);
          setAllowSave(response.data.allowSave);
          setCities(response.data.lists.cities);
          setProvinces(response.data.lists.provinces);
          setDivisions(response.data.lists.divisions);
          setDistricts(response.data.lists.districts);
        })
        .finally(() => {
          setLoading2(false);
        });
    }
  }, [siteInfo, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
    // Clear the error message when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  function saveData(e) {
    let btnName = e.target.name;
    e.preventDefault();
    const newErrors = {};
    // Check for empty fields and apply custom validations
    reqCols.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      return;
    }

    setLoading2(true);
    var formData2 = new FormData();

    Object.keys(formData).forEach((key) => {
      formData2.append(`Contact[${key}]`, formData[key]);
    });

    formData2.append(`Contact[country]`, formData.country.value);
    formData2.append(`Contact[city]`, formData.city.value);
    if (siteInfo.domicile_level === "2") {
      formData2.append(`Contact[division]`, formData.division.value);
    }
    if (siteInfo.domicile_level === "3") {
      formData2.append(`Contact[district]`, formData.district.value);
    }

    attApi
      .post("/add-contact-info", formData2)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validating the Form", "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
            if (btnName === "savenext-button") {
              if (siteInfo.show_gaurdian === "1") {
                navigate(`/guardian`);
              } else {
                navigate(`/academic`);
              }
            }
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading2(false);
      });
  }
  return (
    <div className="">
      {loading && siteInfo === null ? (
        <DataLoading />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row">
                {siteInfo && siteInfo.domicile_level === "1" ? (
                  <div className="col-md-4">
                    <DropDownList
                      name="province"
                      label={formMeta.province}
                      value={formData.province}
                      handleInputChange={handleInputChange}
                      reqCols={reqCols}
                      prompt={"-Select-"}
                      listData={provinces}
                      errors={errors}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {siteInfo && siteInfo.domicile_level === "2" ? (
                  <div className="col-md-4">
                    <Select2
                      name="division"
                      label={formMeta.division}
                      value={formData.division}
                      handleInputChange={handleInputChange}
                      reqCols={reqCols}
                      prompt={"-Select-"}
                      listData={divisions}
                      errors={errors}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {siteInfo && siteInfo.domicile_level === "3" ? (
                  <div className="col-md-4">
                    <Select2
                      name="district"
                      label={formMeta.district}
                      value={formData.district}
                      handleInputChange={handleInputChange}
                      reqCols={reqCols}
                      prompt={"-Select-"}
                      listData={districts}
                      errors={errors}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-4">
                  <Select2
                    name="city"
                    label={formMeta.city}
                    value={formData.city}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    prompt={"-Select-"}
                    listData={cities}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <Select2
                    name="country"
                    label={formMeta.country}
                    value={formData.country}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    prompt={"-Select-"}
                    listData={CountriesList}
                    errors={errors}
                  />
                </div>

                <div className="col-md-4">
                  <TextArea2
                    name="c_address"
                    label={formMeta.c_address}
                    value={formData.c_address}
                    length={250}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="House No. 432 Street 23 Sector 2, Korangi"
                  />
                </div>
                <div className="col-md-4">
                  <TextArea2
                    name="address"
                    label={formMeta.address}
                    value={formData.address}
                    length={250}
                    handleInputChange={handleInputChange}
                    reqCols={reqCols}
                    errors={errors}
                    placeholder="House No. 432 Street 23 Sector 2, Korangi"
                  />
                </div>
              </div>
              {siteInfo && siteInfo.detail_info === "1" ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        name="emergency_no"
                        label={formMeta.emergency_no}
                        value={formData.emergency_no}
                        length={20}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        errors={errors}
                        placeholder={"+923003323434"}
                      />
                    </div>
                    <div className="col-md-4">
                      <DropDownList
                        name="ecp_relation"
                        label={formMeta.ecp_relation}
                        value={formData.ecp_relation}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        prompt={"-Select-"}
                        listData={props.relations}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-4">
                      <DropDownList
                        name="transport"
                        label={formMeta.transport}
                        value={formData.transport}
                        handleInputChange={handleInputChange}
                        reqCols={reqCols}
                        prompt={"-Select-"}
                        listData={YesNoList}
                        errors={errors}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="form-group mt-3">
                {loading2 ? (
                  <DataLoading />
                ) : (
                  <>
                    {allowSave ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        name="save-button"
                        onClick={saveData}
                      >
                        {Trans(
                          applicantStatus &&
                            applicantStatus.contactInfo === true
                            ? "Update"
                            : "Save"
                        )}
                      </button>
                    ) : (
                      <></>
                    )}
                    {applicantStatus && applicantStatus.contactInfo === true &&
                    applicantStatus.applied === false? (
                      <button
                        type="submit"
                        className="btn btn-outline-secondary m-2"
                        name="savenext-button"
                        onClick={saveData}
                      >
                        {Trans("Next")}
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Contact;
