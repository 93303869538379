import React from "react";
import AdmissionCategory from "./AdmissionCategory";

function AdmissionPlan(props) {
  return (
  <div className="card mb-3">
      <div className="card-body">
        <h3>{props.data.name}</h3>
        <p>{props.data.description}</p>
        {props.data.categories.length === 0 ? (
          <></>
        ) : (
          props.data.categories.map(function (d, index) {
            return (
              <AdmissionCategory
                data={d}
                navigateTo = {props.navigateTo}
                key={index}
                user={props.user}
                setReloadPage = {props.setReloadPage}
                selectedCategories={props.selectedCategories}
                allowSave ={props.allowSave}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

export default AdmissionPlan;
