import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../helper/axios";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import Trans from "../helper/Trans";
import { useNavigate } from "react-router-dom";
import { notify } from "../helper/Helper";

const attApi = axiosInstance;
function Apply(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [haveError, setHaveError] = useState(false);
  const [tcc, setTcc] = useState(false);
  const navigate = useNavigate();
  const [degreeInfo, setDegreeInfo] = useState([]);
  const [applicantInfo , setApplicantInfo] = useState({});

  const {
    setTitle,
    setOperation,
    setPLink,
    siteInfo,
    getFillStatus
  } = useContext(UserContext);
  useEffect(() => {
    setTitle("Application");
    setOperation("Submit Application");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  function acceptTerms(e) {
    setTcc(e.target.checked);
  }
  function applyReq(e) {
    setLoading(true);
    e.preventDefault();

    attApi
      .post("/apply")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            notify(res.data.message, "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
            navigate(`/`);
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (siteInfo !== null) {
      setLoading(true);
      attApi.get("get-application-fill-status").then((response) => {
        if (response.data.success === false) {
          setHaveError(true);
          setError(response.data.message);
        }else{
          setDegreeInfo(response.data.degrees);
          setApplicantInfo(response.data.applicant);
        }
        setLoading(false);
      });
    }
  }, [siteInfo]);

  return (
    <div>
      {loading ? (
        <DataLoading />
      ) : haveError ? (
        <p className="text-16">{error}</p>
      ) : (
        <>
          <h3>{applicantInfo.name}</h3>
          <h5>{applicantInfo.father} - {applicantInfo.cnic}</h5>
          <h5 className="mt-3">{Trans('Applied Degrees')}</h5>

          {
            degreeInfo.map(function (d, index) {
              return <div key={index}>
                {index+1}. {d.name}   {' [Preference : '+ d.pref+']'}
                </div>
            })
          }
          <label className="checkbox checkbox-primary mt-3">
            <input type="checkbox" value={tcc} onChange={acceptTerms} />
            {Trans(
              "I hereby affirm that the information provided is accurate to the best of my knowledge."
            )}
            <span className="checkmark"></span>
          </label>

          <button
            type="submit"
            className="btn btn-primary mt-2"
            name="login-button"
            onClick={applyReq}
            disabled={!tcc}
          >
            {Trans("Submit")}
          </button>
        </>
      )}
    </div>
  );
}

export default Apply;
