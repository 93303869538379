import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../helper/axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";

function Validateotp(props) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  function handleOTPChange(e) {
    setOtp(e.target.value);
    setErrorText("");
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
    setErrorText("");
  }

  const loginApi = axiosInstance;
  let msg = Trans("Please change your password.");
  function validate(e) {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("ValidateOtp[otp]", otp);
    loginApi
      .post("/validate-otp", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            setErrorText(res.data.errors.otp);
            notify(res.data.message, "error");
          } else {
            notify(msg, "success");
            setResetToken(res.data.resetToken);
            setResetPassword(true);
          }
        } else {
          setErrorText("Invalid OTP Code Provided!");
          notify(res.message, "error");
        }
      })
      .catch((err) => {
        setErrorText(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function changePassword(e) {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("ResetPassword[password]", password);
    loginApi
      .post("/reset-password?token=" + resetToken, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            setErrorText(res.data.errors.password);
            notify(res.data.message, "error");
          } else {
            notify(msg, "success");
            navigate("/login");
          }
        } else {
          setErrorText("Invalid OTP Code Provided!");
          notify(res.message, "error");
        }
      })
      .catch((err) => {
        setErrorText(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="auth-layout-wrap">
      <div className="auth-content">
        <div className="card o-hidden">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4">
                <div className="auth-logo text-center mb-4">
                  <a href="/">
                    <img src="/imgs/logo.png" alt="Admission Pro" />
                  </a>
                </div>
                {resetPassword === false ? (
                  <div className="site-login">
                    <h3>{Trans("Enter OTP")}</h3>

                    <p>
                      {Trans(
                        "Please fill out the following fields to set password"
                      )}
                      :
                    </p>

                    <form id="login-form" data-gtm-form-interact-id="0">
                      <div
                        className={
                          "form-group field-otp required " +
                          (errorText === "" ? "" : "has-error")
                        }
                      >
                        <label htmlFor="otp">
                          {Trans("Please OTP Code Here")}
                        </label>
                        <input
                          type="text"
                          id="otp"
                          className="form-control"
                          name="otp"
                          autoFocus=""
                          aria-required="true"
                          data-gtm-form-interact-field-id="0"
                          aria-invalid="false"
                          onChange={handleOTPChange}
                          value={otp}
                        />

                        <div className="help-block">{errorText}</div>
                        <p>{Trans("The OTP email may take up to 5 minutes to arrive, so please be patient. It might also land in your spam folder, so be sure to check there. Generating multiple OTPs can cause confusion, so avoid requesting new ones repeatedly.")}</p>
                      </div>

                      <div className="form-group mt-3">
                        {loading ? (
                          <DataLoading loadTxt="Authenticating....." />
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            name="login-button"
                            onClick={validate}
                          >
                            {Trans("Validate")}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="site-login">
                    <h3>{Trans("Change Password")}</h3>

                    <p>
                      {Trans(
                        "Please fill out the following fields to set password"
                      )}
                      :
                    </p>

                    <form id="login-form" data-gtm-form-interact-id="0">
                      <i
                        className="login-eye"
                        id="login_eye"
                        style={{
                          backgroundImage: showPassword
                            ? 'url("/imgs/eye_close.svg")'
                            : 'url("/imgs/icon-preview.svg")',
                        }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      ></i>
                      <div
                        className={
                          "form-group field-password required " +
                          (errorText === "" ? "" : "has-error")
                        }
                      >
                        <label htmlFor="password">
                          {Trans("New Password")}
                        </label>
                        <input
                          type={showPassword?"input":"password"}
                          id="password"
                          className="form-control"
                          name="password"
                          autoFocus=""
                          aria-required="true"
                          data-gtm-form-interact-field-id="0"
                          aria-invalid="false"
                          onChange={handlePasswordChange}
                          value={password}
                        />

                        <div className="help-block">{errorText}</div>
                      </div>

                      <div className="form-group mt-3">
                        {loading ? (
                          <DataLoading loadTxt="Validating....." />
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            name="login-button"
                            onClick={changePassword}
                          >
                            {Trans("Change")}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Validateotp;
