import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/UserContext";

import axiosInstance from "../helper/axios";
import DataLoading from "../components/DataLoading";
import { Link, useParams } from "react-router-dom";
import Trans from "../helper/Trans";

const attApi = axiosInstance;
function NewsItem(props) {
  const { newsid } = useParams();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({});

  const { setTitle, setOperation, setPLink, siteInfo, user } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("News");
    setOperation("News");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  useEffect(() => {
    setLoading(true);
    if (siteInfo !== null) {
      attApi
        .get("get-news-item?uniId=" + siteInfo.app_id + "&id=" + newsid)
        .then((response) => {
          setNews(response.data.data);
          setLoading(false);
        });
    }
  }, [siteInfo, newsid]);

  function createMarkup() {
    return { __html: news.detail };
  }
  return (
    <div className={user === null ? "container" : ""}>
      {user === null ? (
        <>
          <h3 className="mt-3">{Trans("News")}</h3>
          <hr />
        </>
      ) : (
        <></>
      )}
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <h4 className="mt-4">
            {news.title}
            <Link className="float-right btn btn-primary" to="/news">
              {" "}
              {Trans("View All")}
            </Link>
          </h4>
          <p>
            {news.dated} | {news.created_by_name}
          </p>

          <div className="mt-2" dangerouslySetInnerHTML={createMarkup()} />
        </>
      )}

      <div className="flex-grow-1"></div>
    </div>
  );
}

export default NewsItem;
